import styled from 'styled-components';
import { Text } from '../../components/UiKit/Text';
import { Colors } from '../../themes/colors';
import { convertHexToRGBA } from '../../utils/convertHexToRGBA';

const CloseButton = styled(Text)`
  position: absolute;
  top: -14px;
  right: -14px;
  cursor: pointer;
  background: ${Colors.white};
  color: ${Colors.black};
  box-shadow: 0px 0px 23px -2px ${convertHexToRGBA(Colors.black)};
  line-height: unset;
  border-radius: 50%;
  padding: 5px;
`;

const Styles = {
  CloseButton,
};

export { Styles };
