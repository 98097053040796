import React from 'react';
import { Drawer } from '../../components/RightDrawer';
import { Column } from '../../components/UiKit/Column';
import { SizedBox } from '../../components/UiKit/SizedBox';
import { Text } from '../../components/UiKit/Text';
import { Colors } from '../../themes/colors';
import { SingleRole } from './interface';

interface RProps {
  showViewRoleDrawer: boolean;
  setShowViewRoleDrawer: any;
  roleToView: SingleRole | undefined;
}

export const ViewRole: React.FC<RProps> = ({
  showViewRoleDrawer,
  setShowViewRoleDrawer,
  roleToView,
}) => {
  return (
    <Drawer
      showDrawer={showViewRoleDrawer}
      setShowDrawer={setShowViewRoleDrawer}
    >
      <Text weight={600}>Role Details</Text>

      <SizedBox height={50} />

      <Column>
        <Text color={Colors.blackGrey}>Name</Text>
        <Text>{roleToView?.name}</Text>
      </Column>
      <SizedBox height={20} />
      <Column>
        <Text color={Colors.blackGrey}>Description</Text>
        <Text>{roleToView?.description}</Text>
      </Column>
    </Drawer>
  );
};
