// eslint-disable-next-line
import React from 'react';
import { Column } from '../Column';
import { Copyright } from '../Copyright';
import { SizedBox } from '../SizedBox';
import { Styles } from './styles';

export const PageBody: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    centeralize?: boolean;
    dark?: boolean;
    showCopyright?: boolean;
  }
> = (props) => {
  const { children, centeralize = false, showCopyright, ...rest } = props;
  return (
    <Styles.PageBody {...rest} centeralize={centeralize}>
      {children}

      <SizedBox height={50} />
      {showCopyright && (
        <Column alignItems="center" justifyContent="center">
          <Copyright />
        </Column>
      )}
    </Styles.PageBody>
  );
};

PageBody.defaultProps = {
  showCopyright: true,
};
