import React, { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Drawer } from '../../../components/RightDrawer';
import { Text } from '../../../components/UiKit/Text';
import { SizedBox } from '../../../components/UiKit/SizedBox';
import { TextField } from '../../../components/UiKit/TextField';
import { Row } from '../../../components/UiKit/Row';
import { Button } from '../../../components/UiKit/Button';
import { getFieldError } from '../../../utils/formikHelper';
import { Column } from '../../../components/UiKit/Column';
import { useGlobalStore } from '../../../store';
import { Modal } from '../../../components/UiKit/Modal';
import { IError, emptyError } from './interface';
import { ErrorBox } from '../../../components/UiKit/ErrorBox';
import { logger } from '../../../utils/logger';
import { usePost } from '../../../hooks/useRequests';

export const NewPage: FC<{
  showNewPageDrawer: boolean;
  setShowNewPageDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  setNewPageSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setShowNewPageDrawer, showNewPageDrawer, setNewPageSuccess }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [updateError, setUpdateError] = useState<IError>(emptyError);

  const [addPage, { error: addPageError, loading: creating }] = usePost<
    Response
  >(`Mobility.AccountBackOffice/api/Ads/AddPage`);
  const {
    state: {
      auth: { user },
    },
  } = useGlobalStore();

  const formik = useFormik({
    initialValues: {
      pageId: '',
      name: '',
      description: '',
      createdBy: String(user?.userId),
    },
    validationSchema: Yup.object({
      pageId: Yup.string().required('Page ID is required').min(2).max(20),
      name: Yup.string().required('Page name is required').min(2).max(30),
      description: Yup.string()
        .required('Page description is required')
        .min(2)
        .max(40),
      createdBy: Yup.string().required('Creator is required'),
    }),
    onSubmit: () => {
      setShowConfirmationModal(true);
    },
  });

  const handleNewPage = async () => {
    try {
      const response = await addPage({
        ...formik.values,
      });
      if (response.data) {
        setShowConfirmationModal(false);
        setNewPageSuccess(true);
      }
    } catch (errorResp) {
      logger.log(errorResp);
    }
  };

  useEffect(() => {
    if (addPageError) {
      setUpdateError(addPageError);
    }
  }, [addPageError]);

  const UpdateError = updateError?.message && (
    <ErrorBox>{updateError?.message}</ErrorBox>
  );

  const renderModals = () => (
    <>
      <Modal
        isVisible={showConfirmationModal}
        onClose={() => {
          setShowConfirmationModal(false);
          setUpdateError(emptyError);
        }}
        header={{ title: 'Confirmation' }}
        size="sm"
      >
        {UpdateError}

        <SizedBox height={15} />
        <Column>
          <Text>Hi {user?.name},</Text>
          <SizedBox height={15} />
          <Text>Please confirm new page addition</Text>
          <SizedBox height={10} />
          <Row useAppMargin>
            <Column xs={6} useAppMargin>
              <Button
                onClick={() => handleNewPage()}
                isLoading={creating}
                fullWidth
              >
                Confirm
              </Button>
            </Column>
            <Column xs={6} useAppMargin>
              <Button
                onClick={() => {
                  setShowConfirmationModal(false);
                  setUpdateError(emptyError);
                }}
                outline
                fullWidth
                variant="tertiary"
              >
                Cancel
              </Button>
            </Column>
          </Row>
        </Column>
      </Modal>
    </>
  );

  return (
    <Drawer showDrawer={showNewPageDrawer} setShowDrawer={setShowNewPageDrawer}>
      <Text size={18} weight={600}>
        New Page
      </Text>
      <SizedBox height={50} />
      <form onSubmit={formik.handleSubmit}>
        <TextField
          label="Page ID"
          placeholder="Enter Page ID"
          {...formik.getFieldProps('pageId')}
          type="text"
          error={getFieldError(formik.errors.pageId, formik.touched.pageId)}
          minLength={2}
          maxLength={20}
        />

        <TextField
          label="name"
          placeholder="Enter Page name"
          {...formik.getFieldProps('name')}
          type="text"
          error={getFieldError(formik.errors.name, formik.touched.name)}
          minLength={2}
          maxLength={30}
        />

        <TextField
          label="description"
          placeholder="Enter Page description"
          {...formik.getFieldProps('description')}
          type="text"
          multiline
          as="textarea"
          error={getFieldError(
            formik.errors.description,
            formik.touched.description,
          )}
          minLength={2}
          maxLength={40}
        />

        <Row justifyContent="flex-end">
          <Button>Create Page</Button>
        </Row>
      </form>
      {renderModals()}
    </Drawer>
  );
};
