// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Styles } from './styles';
import { ReactComponent as DownloadIcon } from '../../../assets/images/download-icon.svg';
import { ReactComponent as Iphone } from '../../../assets/images/iphone_11.svg';
import { TopBar } from '../../../components/TopBar';
import { BackButton } from '../../../components/UiKit/BackButton';
import { Button } from '../../../components/UiKit/Button';
import { Card } from '../../../components/UiKit/Card';
import { Column } from '../../../components/UiKit/Column';
import { Row } from '../../../components/UiKit/Row';
import { Text } from '../../../components/UiKit/Text';
import { TextField } from '../../../components/UiKit/TextField';
import { useGlobalStore } from '../../../store';
import { getFieldError } from '../../../utils/formikHelper';
import { rem } from '../../../utils/rem';
import { IError, emptyError } from './interface';
import { ErrorBox } from '../../../components/UiKit/ErrorBox';
import { logger } from '../../../utils/logger';
import { SizedBox } from '../../../components/UiKit/SizedBox';
import { Modal } from '../../../components/UiKit/Modal';
import { useFetch, usePost } from '../../../hooks/useRequests';
import { IPage } from '../Pages/interface';
import { Colors } from '../../../themes/colors';

interface SuccessResp {
  responseCode: number;
  message: string;
  result: string;
}

export const NewAdvert = () => {
  const history = useHistory();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [updateError, setUpdateError] = useState<IError>(emptyError);
  const [newAdvertSucess, setNewAdvertSuccess] = useState(false);
  const [fileError, setFileError] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File>(new File([''], ''));
  const [imagePreview, setImagePreview] = useState('');
  const [addNewAdvert, { error: newAddError, loading: creating }] = usePost<
    Response
  >(`Mobility.AccountBackOffice/api/Ads/AddAd`);

  const [uploadImage, { loading: uploading }] = usePost<SuccessResp>(
    'Mobility.AccountBackOffice/api/Uploads/UploadImage',
  );

  const { data: pagesData } = useFetch<IPage>(
    `Mobility.AccountBackoffice/api/Ads/GetPagesNameAndId`,
  );
  const [pages, setPages] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  useEffect(() => {
    if (pagesData) {
      const results = pagesData?.result?.map((option) => ({
        label: option.name,
        value: String(option.id),
      }));

      setPages(results);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesData]);

  const {
    state: {
      auth: { user },
    },
  } = useGlobalStore();

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      createdBy: String(user?.userId),
      pageId: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Advert name is required').min(4).max(20),
      description: Yup.string()
        .required('Advert description is required')
        .min(4)
        .max(40),
      createdBy: Yup.string().required('Creator is required'),
      pageId: Yup.string().required('Please select a page'),
    }),
    onSubmit: () => {
      if (selectedFile.name !== '') {
        setShowConfirmationModal(true);
      } else {
        setFileError('please select a valid image file');
      }
    },
  });

  const handleNewAdvert = async (imageUrl: string) => {
    try {
      const response = await addNewAdvert({
        ...formik.values,
        pageId: Number(formik.values.pageId),
        imageUrl,
      });
      if (response.data) {
        setShowConfirmationModal(false);
        setNewAdvertSuccess(true);
      }
    } catch (errorResp) {
      logger.log(errorResp);
    }
  };

  const handleNewImage = () => {
    if (selectedFile.name !== '') {
      (async () => {
        const data = new FormData();
        data.append('ProfileImage', selectedFile);
        try {
          const response = await uploadImage(data);
          if (response.data) {
            setSelectedFile(new File([''], ''));
            setFileError('');
            // update the photoUrl in global state
            if (response?.data?.result) {
              handleNewAdvert(response?.data?.result);
            }
          }
        } catch (errorResp) {
          setFileError((errorResp as Error).message);
        }
      })();
    }
  };

  useEffect(() => {
    if (newAddError) {
      setUpdateError(newAddError);
    }
  }, [newAddError]);

  const UpdateError = updateError?.message && (
    <ErrorBox>{updateError?.message}</ErrorBox>
  );

  const isFileImage = (file: File) => {
    const acceptedImageTypes = ['image/jpeg', 'image/png'];

    return file && acceptedImageTypes.includes(file.type);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files instanceof FileList) {
      if (isFileImage(event.target.files[0])) {
        const file = event.target.files[0];
        const fileSize = parseFloat(`${file.size / (1024 * 1024)}`).toFixed(2);

        if (Number(fileSize) > 1) {
          setFileError('Please select image size less than 1 MB');
        } else {
          const img = new Image();

          img.src = window.URL.createObjectURL(file);

          img.onload = () => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;

            if (width <= 375 && height <= 76) {
              // ok
              setFileError('');
              setImagePreview(URL.createObjectURL(file));

              setSelectedFile(file);
            } else {
              // error
              setFileError('Image must have width <= 375 and height <= 76');
            }
          };
        }
      } else {
        setFileError('Please select a valid JPEG or PNG file');
      }
    } else {
      setFileError('invalid file selected');
    }
  };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const handleClick = () => {
    if (hiddenFileInput.current !== null) {
      hiddenFileInput && hiddenFileInput.current.click();
    }
  };

  const renderModals = () => (
    <>
      <Modal
        isVisible={showConfirmationModal}
        onClose={() => {
          setShowConfirmationModal(false);
          setUpdateError(emptyError);
        }}
        header={{ title: 'Confirmation' }}
        size="sm"
      >
        {UpdateError}

        <SizedBox height={15} />
        <Column>
          <Text>Hi {user?.name},</Text>
          <SizedBox height={15} />
          <Text>Please confirm new advert creation</Text>
          <SizedBox height={10} />
          <Row useAppMargin>
            <Column xs={6} useAppMargin>
              <Button
                onClick={() => handleNewImage()}
                isLoading={creating || uploading}
                fullWidth
              >
                Confirm
              </Button>
            </Column>
            <Column xs={6} useAppMargin>
              <Button
                onClick={() => {
                  setShowConfirmationModal(false);
                  setUpdateError(emptyError);
                }}
                outline
                fullWidth
                variant="tertiary"
              >
                Cancel
              </Button>
            </Column>
          </Row>
        </Column>
      </Modal>

      <Modal
        isVisible={newAdvertSucess}
        onClose={() => {
          setNewAdvertSuccess(false);
          history.push('/ads');
        }}
        size="sm"
      >
        <SizedBox height={15} />
        <Column>
          <Text>Hi {user?.name},</Text>
          <SizedBox height={15} />
          <Text>New advert created successfully</Text>
          <SizedBox height={10} />
          <Button
            onClick={() => {
              setNewAdvertSuccess(false);
              history.push('/ads');
            }}
            fullWidth
          >
            Done
          </Button>
        </Column>
      </Modal>
    </>
  );

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <TopBar name="User Administration" />
        <Card fullWidth style={{ padding: '1.5% 4%', boxShadow: 'none' }}>
          <Row justifyContent="space-between">
            <BackButton>
              <Text size={18} weight={600}>
                New Advert
              </Text>
            </BackButton>
            <Button type="submit">Post Advert</Button>
          </Row>
        </Card>
        <Row childGap={10} style={{ padding: rem(40) }}>
          <Column xs={12} md={6}>
            <Card fullWidth>
              <TextField
                label="name"
                placeholder="Enter Advert name"
                {...formik.getFieldProps('name')}
                type="text"
                error={getFieldError(formik.errors.name, formik.touched.name)}
                minLength={4}
                maxLength={20}
              />

              <TextField
                label="description"
                placeholder="Enter Advert description"
                {...formik.getFieldProps('description')}
                type="text"
                multiline
                as="textarea"
                error={getFieldError(
                  formik.errors.description,
                  formik.touched.description,
                )}
                minLength={4}
                maxLength={40}
              />
              <SizedBox height={10} />

              <Text>
                Image Upload
                <Text color={Colors.blackGrey}>
                  (dimensions must be 375 X 76)
                </Text>
              </Text>
              <SizedBox height={10} />
              <Column
                alignItems="center"
                justifyContent="center"
                onClick={handleClick}
                style={{
                  border: `dotted 2px ${Colors.grey}`,
                  padding: imagePreview !== '' ? '0px' : rem(30),
                  borderRadius: '5px',
                }}
              >
                {imagePreview !== '' ? (
                  <Column
                    onClick={handleClick}
                    style={{ position: 'relative' }}
                  >
                    <Styles.CloseButton
                      onClick={() => {
                        setImagePreview('');
                        setSelectedFile(new File([''], ''));
                      }}
                      size={14}
                      weight={600}
                    >
                      x
                    </Styles.CloseButton>
                    <img
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                      src={imagePreview}
                      alt="ad"
                    />
                  </Column>
                ) : (
                  <>
                    <DownloadIcon />
                    <Text>Click to browse</Text>
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={handleChange}
                      style={{ display: 'none' }}
                    />
                  </>
                )}
              </Column>
              {fileError && (
                <Text color={Colors.orange}>{fileError.toString()}</Text>
              )}
              <SizedBox height={20} />
              <TextField
                label="Select Page"
                placeholder="Select Page"
                dropDown
                dropDownOptions={pages}
                value={formik.values.pageId}
                onChange={(e) => formik.setFieldValue('pageId', e.target.value)}
                type="text"
                error={getFieldError(
                  formik.errors.pageId,
                  formik.touched.pageId,
                )}
              />
            </Card>
          </Column>
          <Column xs={12} md={3}>
            <Card fullWidth>
              <Text weight={600}>Sample Ad placement</Text>
              <SizedBox height={30} />
              <Column justifyContent="center">
                <Iphone />
              </Column>
            </Card>
          </Column>
        </Row>
      </form>

      {renderModals()}
    </>
  );
};
