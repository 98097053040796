// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import { DateTime } from 'luxon';
import { PageBody } from '../../components/UiKit/PageBody';
import { TopBar } from '../../components/TopBar';
import { Column } from '../../components/UiKit/Column';
import { SizedBox } from '../../components/UiKit/SizedBox';
import { ReactComponent as DropzoneIcon } from '../../assets/images/dropzone-icon.svg';
import { Row } from '../../components/UiKit/Row';
import { Button } from '../../components/UiKit/Button';
import { TextField } from '../../components/UiKit/TextField';
import { Card } from '../../components/UiKit/Card';
import { ErrorBox } from '../../components/UiKit/ErrorBox';
import { paginationLimits } from '../../utils/paginationLimits';
import { SimpleTable } from '../../components/UiKit/Table';
import { useFetch, useLazyFetch, usePost } from '../../hooks/useRequests';
import { Pagination } from '../../components/UiKit/Pagination';
import { Text } from '../../components/UiKit/Text';
import { generateShortId } from '../../utils/generateShortId';
import { ReactComponent as FilterIcon } from '../../assets/images/filter-icon.svg';
import { Drawer } from '../../components/RightDrawer';
import { Colors } from '../../themes/colors';
import { Styles } from './styles';
import { logger } from '../../utils/logger';
import {
  IReservedSims,
  ICat,
  SuccessResp,
  IError,
  emptyError,
} from './interface';
import { convertHexToRGBA } from '../../utils/convertHexToRGBA';
import { Spinner } from '../../components/UiKit/Spinner';
import { Modal } from '../../components/UiKit/Modal';
import { useGlobalStore } from '../../store';
import { useGetSearchMobilePattern } from '../../hooks/useSearch';
import { DownloadLink } from '../../components/UiKit/DownloadLink';

export const SimReservation = () => {
  const {
    state: {
      auth: { user },
    },
  } = useGlobalStore();

  const [searchMobile, setSearchMobile] = useState('');
  const search = useGetSearchMobilePattern(searchMobile);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [simId, setSimId] = useState<number>();
  const [updateError, setUpdateError] = useState<IError>(emptyError);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [showDrawer, setShowDrawer] = useState(false);
  const [category, setCategory] = useState('');
  const [uploadCategory, setUploadCategory] = useState('');
  const [catError, setCatError] = useState('');
  const [audit, setAudit] =
    useState<(string | number | React.FC | JSX.Element)[][]>();

  const [parsedData, setParsedData] = useState<unknown[][]>();
  const [isParsing, setIsParsing] = useState(false);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({});

  const url = `Mobility.EShop/api/SimReservation/GetAllSims/${category}/${pageNumber}/${pageSize}${search}`;

  const [loadSims, { data, loading, error }] = useLazyFetch<IReservedSims>(url);

  const [
    uploadSimsCsv,
    { loading: uploading, data: uploadData, error: uploadErr },
  ] = usePost<SuccessResp>('Mobility.EShop/api/SimReservation/UploadSims');

  const [deleteReservedSim, { error: deleteErr, loading: deleting }] = usePost<{
    message: string;
  }>(`Mobility.EShop/api/SimReservation/DeleteReservedSim?id=${simId}`);

  const { data: catData } = useFetch<ICat[]>(
    `Mobility.EShop/api/SimReservation/GetSimTypes`,
  );
  const [categories, setCategories] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  useEffect(() => {
    if (catData) {
      const results = catData?.map((option) => ({
        label: option.description,
        value: String(option.id),
      }));
      setCategory(results[0].value);
      setCategories(results);
    }
  }, [catData]);

  useEffect(() => {
    if (category) {
      (async () => {
        try {
          await loadSims();
        } catch (errorResp) {
          logger.log(errorResp);
        }
      })();
    }
  }, [category, loadSims]);

  const handleDelete = async () => {
    try {
      const response = await deleteReservedSim();
      if (response.data) {
        setDeleteConfirmation(false);
        loadSims();
      }
    } catch (errorResp) {
      logger.log(errorResp);
    }
  };

  useEffect(() => {
    if (data?.data?.length) {
      const result = data?.data?.map((r, i) =>
        Object.values({
          'S/N': i + 1,
          availableNumbers: r.msisdn,
          Amount: r?.amount,
          Status: (
            <Text
              style={{
                background: r.isReserved
                  ? convertHexToRGBA('#A80000', 0.1)
                  : 'rgba(0, 168, 17, 0.1)',
                padding: '0.5rem',
              }}
              key={generateShortId()}
              size={12}
              weight="bold"
              color={r.isReserved ? '#6A0000' : Colors.darkGreen}
            >
              {r.isReserved ? 'Reserved' : 'Available'}
            </Text>
          ),
          categoryName: r.categoryName,
          dateUploaded: DateTime.fromISO(r?.dateUploaded, {
            locale: 'ng',
          }).toLocaleString(DateTime.DATETIME_MED),
          action: (
            <Button
              link
              color={Colors.darkGreen}
              key={generateShortId()}
              onClick={() => {
                setDeleteConfirmation(true);
                setSimId(r.id);
              }}
            >
              Delete
            </Button>
          ),
        }),
      );

      setAudit(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data]);

  useEffect(() => {
    if (acceptedFiles.length) {
      setIsParsing(true);
      const csvData: unknown[][] = [];
      Papa.parse(acceptedFiles[0], {
        header: true,
        step(result) {
          // csvData.push({ ...result.data });
        },
        complete() {
          setIsParsing(false);
          setParsedData(csvData);
        },
      });
    }
  }, [acceptedFiles]);

  const handleCsvUpload = async () => {
    if (uploadCategory) {
      try {
        const response = await uploadSimsCsv({
          categoryId: uploadCategory,
          packages: parsedData,
        });
        if (response.data) {
          setShowDrawer(false);
          setShowSuccessModal(true);
        }
      } catch (errorResp) {
        logger.log(errorResp);
      }
    } else {
      setCatError('Please select a category');
    }
  };

  useEffect(() => {
    if (deleteErr) {
      setUpdateError(deleteErr);
    }
  }, [deleteErr]);

  const UpdateError = updateError?.message && (
    <ErrorBox>{updateError?.message}</ErrorBox>
  );

  const renderModals = () => (
    <>
      <Modal
        isVisible={showSuccessModal}
        // onClose={() => history.push('/login')}
        size="sm"
      >
        <SizedBox height={15} />
        <Column>
          <Text>Hi {user?.name},</Text>
          <SizedBox height={15} />
          <Text>{uploadData?.message}</Text>
          <SizedBox height={10} />
          <Button
            onClick={() => {
              setShowSuccessModal(false);
              setParsedData(undefined);
              loadSims();
            }}
            fullWidth
          >
            Done
          </Button>
        </Column>
      </Modal>

      <Modal
        isVisible={deleteConfirmation}
        onClose={() => {
          setDeleteConfirmation(false);
        }}
        header={{ title: 'Confirmation' }}
        size="sm"
      >
        {UpdateError}

        <SizedBox height={15} />
        <Column>
          <Text>Hi {user?.name},</Text>
          <SizedBox height={15} />
          <Text>Please confirm you want to delete sim</Text>
          <SizedBox height={10} />
          <Row useAppMargin>
            <Column xs={6} useAppMargin>
              <Button
                onClick={() => handleDelete()}
                isLoading={deleting}
                fullWidth
              >
                Confirm
              </Button>
            </Column>
            <Column xs={6} useAppMargin>
              <Button
                onClick={() => {
                  setDeleteConfirmation(false);
                }}
                outline
                fullWidth
                variant="tertiary"
              >
                Cancel
              </Button>
            </Column>
          </Row>
        </Column>
      </Modal>
    </>
  );

  return (
    <>
      <TopBar name="SIM reservation" />
      <PageBody>
        <Row useAppMargin justifyContent="space-between">
          <Column fullHeight useAppMargin xs={12} md={6}>
            <Row useAppMargin>
              <Column useAppMargin xs={4} md={2}>
                <Button
                  variant="default"
                  fullWidth
                  style={{ padding: '0 10px' }}
                >
                  <FilterIcon /> Filter
                </Button>
              </Column>
              <Column useAppMargin xs={4} md={6}>
                <TextField
                  type="tel"
                  maxLength={11}
                  placeholder="Search by mobile"
                  onChange={(e) => setSearchMobile(e.target.value)}
                />
              </Column>
              <Column useAppMargin xs={4}>
                <TextField
                  placeholder="Select Category"
                  dropDown
                  dropDownOptions={categories}
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                />
              </Column>
            </Row>
          </Column>
          <Column xs={12} md={4} lg={2} justifyContent="flex-end">
            <Button
              fullWidth
              onClick={() => {
                setShowDrawer(true);
                setUploadCategory('');
              }}
            >
              Upload New Batch
            </Button>
          </Column>
        </Row>
        <SizedBox height={24} />
        <Column>
          <Card style={{ padding: '1.5rem' }} fullWidth>
            <SimpleTable
              loading={loading}
              columns={[
                'S//N',
                'Available numbers',
                'Amount',
                'Status',
                'Category',
                'Date uploaded',
                '',
              ]}
              data={audit}
            />
            {error && <ErrorBox>{error.message}</ErrorBox>}

            <Column>
              {data?.data && (
                <Row useAppMargin justifyContent="space-between">
                  <Column xs={4} md={2}>
                    <TextField
                      leftIcon="Show:"
                      placeholder={`${pageSize}`}
                      dropDown
                      dropDownOptions={paginationLimits}
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    />
                  </Column>
                  <Column
                    xs={12}
                    md={8}
                    fullHeight
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Pagination
                      breakLabel="..."
                      pageCount={data?.totalPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={(e) => setPageNumber(e.selected + 1)}
                      containerClassName="pagination"
                      activeClassName="active"
                    />
                  </Column>
                </Row>
              )}
            </Column>
          </Card>
        </Column>
      </PageBody>
      {setShowDrawer && (
        <Drawer showDrawer={showDrawer} setShowDrawer={setShowDrawer}>
          <Text weight={600}>Upload New File</Text>
          <SizedBox height={50} />
          <DownloadLink url="Sim Reservation Template.csv">
            <Button link type="submit">
              Download Template
            </Button>
          </DownloadLink>
          <SizedBox height={50} />
          <TextField
            placeholder="Select Category"
            dropDown
            dropDownOptions={categories}
            value={uploadCategory}
            onChange={(e) => setUploadCategory(e.target.value)}
          />
          {catError && <ErrorBox>{catError}</ErrorBox>}
          <Styles.DropContainer {...getRootProps({})}>
            {isParsing ? (
              <Spinner />
            ) : (
              <>
                {parsedData?.length ? (
                  <>
                    <Text>processed {parsedData.length} records</Text>
                    <Text>Click on upload to proceed</Text>
                    <SizedBox height={20} />
                    <Text
                      onClick={() => setParsedData(undefined)}
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      Clear
                    </Text>
                  </>
                ) : (
                  <>
                    <input {...getInputProps()} />
                    <DropzoneIcon />
                    <SizedBox height={30} />
                    <Text weight={600} color={Colors.black}>
                      Drag your files to upload
                    </Text>
                    <SizedBox height={5} />
                    <Text>or click to browse</Text>
                  </>
                )}
              </>
            )}
          </Styles.DropContainer>
          {uploadErr && <ErrorBox>{uploadErr.message}</ErrorBox>}

          <SizedBox height={20} />

          <Row justifyContent="space-between" childGap={10}>
            <Column xs={12} md={6} style={{ flex: '1' }}>
              <Button
                fullWidth
                outline
                variant="tertiary"
                onClick={() => setShowDrawer(false)}
              >
                Cancel
              </Button>
            </Column>
            <Column xs={12} md={6} style={{ flex: '1' }}>
              <Button
                disabled={!acceptedFiles[0] || uploading}
                fullWidth
                variant="primary"
                onClick={handleCsvUpload}
                isLoading={uploading}
              >
                Upload
              </Button>
            </Column>
          </Row>
        </Drawer>
      )}
      {renderModals()}
    </>
  );
};
