import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Drawer } from '../../components/RightDrawer';
import { Button } from '../../components/UiKit/Button';
import { Column } from '../../components/UiKit/Column';
import { ErrorBox } from '../../components/UiKit/ErrorBox';
import { Modal } from '../../components/UiKit/Modal';
import { Row } from '../../components/UiKit/Row';
import { SizedBox } from '../../components/UiKit/SizedBox';
import { Text } from '../../components/UiKit/Text';
import { TextField } from '../../components/UiKit/TextField';
import { useFetch, usePost } from '../../hooks/useRequests';
import { useGlobalStore } from '../../store';
import { getFieldError, isFutureDate } from '../../utils/formikHelper';
import { logger } from '../../utils/logger';
import { emptyError, IError } from '../Settings/interface';
import { SingleUser } from './interface';
import Moment from 'moment';

interface DProps {
  showEditDrawer: boolean;
  setShowEditDrawer: any;
  setEditUserSuccess: any;
  editUserSuccess: boolean;
  userToEdit?: SingleUser;
}

export interface IRole {
  result: [
    {
      id: number;
      name: string;
    },
  ];
  responseCode: number;
  message: string;
}
export const EditUser: React.FC<DProps> = ({
  showEditDrawer,
  setShowEditDrawer,
  setEditUserSuccess,
  editUserSuccess,
  userToEdit,
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [updateError, setUpdateError] = useState<IError>(emptyError);

  const {
    state: {
      auth: { user },
    },
    // dispatch,
  } = useGlobalStore();

  const [updateUser, { error: updateUserError, loading: updating }] =
    usePost<Response>(`Mobility.OnboardingBackOffice/api/Admins/UpdateProfile`);

  const { data: rolesData } = useFetch<IRole>(
    `Mobility.OnboardingBackOffice/api/Roles/GetNameAndIds`,
  );

  const [roles, setRoles] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  useEffect(() => {
    if (rolesData) {
      const results = rolesData?.result?.map((option) => ({
        label: option.name,
        value: String(option.id),
      }));

      setRoles(results);
    }
  }, [rolesData]);

  const formik = useFormik({
    initialValues: {
      username: '',
      roleId: '',
      email: '',
      firstName: '',
      lastName: '',
      mobileNumber: '',
      dob: '',
      photoUrl: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('email is required'),
      firstName: Yup.string().required('First name is required'),
      roleId: Yup.string().required('Role is required'),
      lastName: Yup.string().required('Last name is required'),
      username: Yup.string().required('Username is required'),
      mobileNumber: Yup.string()
        .matches(/^\d{11}$/, 'Must be an 11 digit phone number')
        .required('Mobile number is required'),
      dob: Yup.string().test('DOB', 'Future dates are not allowed', (value) => {
        return !isFutureDate(value);
      }),
    }),
    onSubmit: (values) => {
      setShowConfirmationModal(true);
    },
  });

  useEffect(() => {
    const roleId = roles.find((_) => _.label === userToEdit?.roleNames[0]);
    if (userToEdit) {
      formik.setFieldValue('username', userToEdit.username);
      formik.setFieldValue('firstName', userToEdit.firstName);
      formik.setFieldValue('lastName', userToEdit.lastName);
      formik.setFieldValue('email', userToEdit.email);
      formik.setFieldValue('mobileNumber', userToEdit.mobileNumber);
      formik.setFieldValue('roleId', roleId?.value);
      formik.setFieldValue(
        'dob',
        Moment(userToEdit.dateOfBirth).format('DD/MM/YYYY'),
      );
    } else {
      formik.setFieldValue('username', '');
      formik.setFieldValue('firstName', '');
      formik.setFieldValue('lastName', '');
      formik.setFieldValue('email', '');
      formik.setFieldValue('mobileNumber', '');
      formik.setFieldValue('roleId', '');
      formik.setFieldValue('dob', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToEdit]);

  const handleNewUser = async () => {
    try {
      if (userToEdit) {
        const response = await updateUser({
          ...formik.values,
          id: userToEdit?.id,
        });
        if (response.data) {
          setShowConfirmationModal(false);
          setEditUserSuccess(true);
        }
      }
    } catch (errorResp) {
      logger.log(errorResp);
    }
  };

  useEffect(() => {
    if (updateUserError) {
      setUpdateError(updateUserError);
    }
  }, [updateUserError]);

  const UpdateError = updateError?.message && (
    <ErrorBox>{updateError?.message}</ErrorBox>
  );

  const renderModals = () => (
    <>
      <Modal
        isVisible={showConfirmationModal}
        onClose={() => {
          setShowConfirmationModal(false);
          setUpdateError(emptyError);
        }}
        header={{ title: 'Confirmation' }}
        size="sm"
      >
        {UpdateError}

        <SizedBox height={15} />
        <Column>
          <Text>Hi {user?.name},</Text>
          <SizedBox height={15} />
          <Text>Please confirm you want to edit user</Text>
          <SizedBox height={10} />
          <Row useAppMargin>
            <Column xs={6} useAppMargin>
              <Button
                onClick={() => handleNewUser()}
                isLoading={updating}
                fullWidth
              >
                Confirm
              </Button>
            </Column>
            <Column xs={6} useAppMargin>
              <Button
                onClick={() => {
                  setShowConfirmationModal(false);
                  setUpdateError(emptyError);
                }}
                outline
                fullWidth
                variant="tertiary"
              >
                Cancel
              </Button>
            </Column>
          </Row>
        </Column>
      </Modal>

      <Modal
        isVisible={editUserSuccess}
        // onClose={() => history.push('/login')}
        size="sm"
      >
        <SizedBox height={15} />
        <Column>
          <Text>Hi {user?.name},</Text>
          <SizedBox height={15} />
          <Text>New user added successfully</Text>
          <SizedBox height={10} />
          <Button onClick={() => setEditUserSuccess(false)} fullWidth>
            Done
          </Button>
        </Column>
      </Modal>
    </>
  );

  return (
    <Drawer showDrawer={showEditDrawer} setShowDrawer={setShowEditDrawer}>
      <Text weight={600}> {userToEdit ? 'Update' : 'New'} User</Text>
      <SizedBox height={50} />
      <form onSubmit={formik.handleSubmit}>
        <TextField
          label="Username"
          placeholder="Enter Username"
          {...formik.getFieldProps('username')}
          type="text"
          error={getFieldError(formik.errors.username, formik.touched.username)}
        />
        <TextField
          label="Email"
          placeholder="Enter email"
          {...formik.getFieldProps('email')}
          type="email"
          error={getFieldError(formik.errors.email, formik.touched.email)}
        />
        <TextField
          label="First Name"
          placeholder="Enter a user name"
          {...formik.getFieldProps('firstName')}
          type="text"
          error={getFieldError(
            formik.errors.firstName,
            formik.touched.lastName,
          )}
        />
        <TextField
          label="Last Name"
          placeholder="Enter last name"
          {...formik.getFieldProps('lastName')}
          type="text"
          error={getFieldError(formik.errors.lastName, formik.touched.lastName)}
        />

        <TextField
          label="Select Role"
          placeholder="Select Role"
          dropDown
          dropDownOptions={roles}
          value={formik.values.roleId}
          onChange={(e) => {
            formik.setFieldValue('roleId', e.target.value);
          }}
          type="text"
          error={getFieldError(formik.errors.roleId, formik.touched.roleId)}
        />

        <TextField
          label="Mobile number"
          placeholder="Mobile number"
          {...formik.getFieldProps('mobileNumber')}
          onChange={(e) => {
            formik.setFieldValue('mobileNumber', e.target.value);
          }}
          type="tel"
          minLength={11}
          maxLength={11}
          error={getFieldError(
            formik.errors.mobileNumber,
            formik.touched.mobileNumber,
          )}
        />

        <TextField
          label="Date of birth"
          placeholder="Select DOB"
          {...formik.getFieldProps('dob')}
          onChange={(e) => {
            formik.setFieldValue('dob', e.target.value);
          }}
          type="date"
          error={getFieldError(formik.errors.dob, formik.touched.dob)}
        />

        <SizedBox height={40} />
        <Row justifyContent="flex-end">
          <Button>{userToEdit ? 'Update' : 'Invite'} User</Button>
        </Row>
      </form>
      {renderModals()}
    </Drawer>
  );
};
