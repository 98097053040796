import React, { FC } from 'react';

interface Props {
  url: string;
}

export const DownloadLink: FC<Props> = (props) => {
  const { url, children } = props;
  return (
    <form method="GET" target="_blank" action={url} className="inline">
      {children}
    </form>
  );
};
