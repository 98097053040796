// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DateTime } from 'luxon';
import { TopBar } from '../../../components/TopBar';
import { Card } from '../../../components/UiKit/Card';
import { Row } from '../../../components/UiKit/Row';
import { BackButton } from '../../../components/UiKit/BackButton';
import { Button } from '../../../components/UiKit/Button';
import { Text } from '../../../components/UiKit/Text';
import { useFetch, usePost } from '../../../hooks/useRequests';
import { ISingleAdd, IError, emptyError } from './interface';
import { Column } from '../../../components/UiKit/Column';
import { rem } from '../../../utils/rem';
import { ReactComponent as Iphone } from '../../../assets/images/iphone_11.svg';
import { SizedBox } from '../../../components/UiKit/SizedBox';
import { Colors } from '../../../themes/colors';
import { Spinner } from '../../../components/UiKit/Spinner';
import { ErrorBox } from '../../../components/UiKit/ErrorBox';

import { Modal } from '../../../components/UiKit/Modal';
import { useGlobalStore } from '../../../store';
import { logger } from '../../../utils/logger';

export const ViewSingleAdd = () => {
  const history = useHistory();
  const { id } = useRouteMatch().params as any;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteConfirmation, setDeleteAdvertConfirmation] = useState(false);
  const [updateError, setUpdateError] = useState<IError>(emptyError);
  const [toggleSuccess, setToggleSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  const {
    state: {
      auth: { user },
    },
  } = useGlobalStore();

  const { data, loading, error } = useFetch<ISingleAdd>(
    `Mobility.AccountBackoffice/api/Ads/GetAd?id=${id}`,
  );

  const [toggleAdStatus, { loading: toggling, error: toggleError }] = usePost<
    ISingleAdd
  >(`Mobility.AccountBackOffice/api/Ads/ToggleActive?id=${id}`);

  const [deleteAd, { loading: deleting, error: deleteError }] = usePost<
    ISingleAdd
  >(`Mobility.AccountBackOffice/api/Ads/DeleteAd?id=${id}`);

  const handleToggle = async () => {
    try {
      const response = await toggleAdStatus();
      if (response.data) {
        setShowConfirmationModal(false);
        setToggleSuccess(true);
      }
    } catch (errorResp) {
      logger.log(errorResp);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await deleteAd();
      if (response.data) {
        setDeleteAdvertConfirmation(false);
        setDeleteSuccess(true);
      }
    } catch (errorResp) {
      logger.log(errorResp);
    }
  };

  useEffect(() => {
    if (toggleError) {
      setUpdateError(toggleError);
    }

    if (deleteError) {
      setUpdateError(deleteError);
    }
  }, [toggleError, deleteError]);

  const UpdateError = updateError?.message && (
    <ErrorBox>{updateError?.message}</ErrorBox>
  );

  const placeholder = 'https://via.placeholder.com/350x65?text=No Advert Image';

  const renderModals = () => (
    <>
      <Modal
        isVisible={showConfirmationModal}
        onClose={() => {
          setShowConfirmationModal(false);
        }}
        header={{ title: 'Confirmation' }}
        size="sm"
      >
        {UpdateError}

        <SizedBox height={15} />
        <Column>
          <Text>Hi {user?.name},</Text>
          <SizedBox height={15} />
          <Text>Please confirm advert status change</Text>
          <SizedBox height={10} />
          <Row useAppMargin>
            <Column xs={6} useAppMargin>
              <Button
                onClick={() => handleToggle()}
                isLoading={toggling}
                fullWidth
              >
                Confirm
              </Button>
            </Column>
            <Column xs={6} useAppMargin>
              <Button
                onClick={() => {
                  setShowConfirmationModal(false);
                }}
                outline
                fullWidth
                variant="tertiary"
              >
                Cancel
              </Button>
            </Column>
          </Row>
        </Column>
      </Modal>

      <Modal
        isVisible={deleteConfirmation}
        onClose={() => {
          setDeleteAdvertConfirmation(false);
        }}
        header={{ title: 'Confirmation' }}
        size="sm"
      >
        {UpdateError}

        <SizedBox height={15} />
        <Column>
          <Text>Hi {user?.name},</Text>
          <SizedBox height={15} />
          <Text>Are you sure you want to delete advert</Text>
          <SizedBox height={10} />
          <Row useAppMargin>
            <Column xs={6} useAppMargin>
              <Button
                onClick={() => handleDelete()}
                isLoading={deleting}
                fullWidth
              >
                Confirm
              </Button>
            </Column>
            <Column xs={6} useAppMargin>
              <Button
                onClick={() => {
                  setDeleteAdvertConfirmation(false);
                }}
                outline
                fullWidth
                variant="tertiary"
              >
                Cancel
              </Button>
            </Column>
          </Row>
        </Column>
      </Modal>

      <Modal
        isVisible={toggleSuccess}
        onClose={() => {
          setToggleSuccess(false);
          history.push('/ads');
        }}
        size="sm"
      >
        <SizedBox height={15} />
        <Column>
          <Text>Hi {user?.name},</Text>
          <SizedBox height={15} />
          <Text>Advert status changed successfully</Text>
          <SizedBox height={10} />
          <Button
            onClick={() => {
              setToggleSuccess(false);
              history.push('/ads');
            }}
            fullWidth
          >
            Done
          </Button>
        </Column>
      </Modal>

      <Modal
        isVisible={deleteSuccess}
        onClose={() => {
          setDeleteSuccess(false);
          history.push('/ads');
        }}
        size="sm"
      >
        <SizedBox height={15} />
        <Column>
          <Text>Hi {user?.name},</Text>
          <SizedBox height={15} />
          <Text>Advert deleted successfully</Text>
          <SizedBox height={10} />
          <Button
            onClick={() => {
              setDeleteSuccess(false);
              history.push('/ads');
            }}
            fullWidth
          >
            Done
          </Button>
        </Column>
      </Modal>
    </>
  );

  return (
    <>
      <TopBar name="User Administration" />
      <Card fullWidth style={{ padding: '1.5% 4%', boxShadow: 'none' }}>
        <Row justifyContent="space-between">
          <Column xs={12} md={6}>
            <BackButton>
              <Text size={18} weight={600}>
                {data?.result?.name}
              </Text>
            </BackButton>
          </Column>

          <Column xs={12} md={6} justifyContent="flex-end">
            <Row childGap={5}>
              <Button onClick={() => history.push(`edit/${id}`)}>Edit</Button>
              {data?.result?.isActive ? (
                <Button
                  onClick={() => setShowConfirmationModal(true)}
                  outline
                  variant="tertiary"
                >
                  Pause Ad
                </Button>
              ) : (
                <Button
                  onClick={() => setShowConfirmationModal(true)}
                  outline
                  variant="tertiary"
                >
                  Activate Ad
                </Button>
              )}

              <Button
                outline
                variant="tertiary"
                onClick={() => setDeleteAdvertConfirmation(true)}
              >
                Delete Ad
              </Button>
            </Row>
          </Column>
        </Row>
      </Card>

      {loading ? (
        <Card fullWidth fullHeight>
          <Spinner isFixed>Fetching advert details</Spinner>
        </Card>
      ) : (
        <>
          {error ? (
            <Card fullWidth fullHeight>
              <ErrorBox>{error.message}</ErrorBox>
            </Card>
          ) : (
            <Row childGap={10} style={{ padding: rem(40) }}>
              <Column xs={12} lg={9}>
                <Row childGap={10}>
                  <Column xs={12} lg={4}>
                    <Text size={18} weight="600">
                      Advert Details
                    </Text>
                    <Text color={Colors.blackGrey}>
                      {data?.result?.description}
                    </Text>
                  </Column>
                  <Column style={{ flex: '1' }} xs={12} lg={8}>
                    <Card fullWidth style={{ padding: '4%' }}>
                      <Text weight="600">{data?.result?.name}</Text>
                      <Text color={Colors.blackGrey}>
                        last Updated:{' '}
                        {DateTime.fromISO(data?.result?.dateCreated || '', {
                          locale: 'ng',
                        }).toLocaleString(DateTime.DATETIME_MED)}
                      </Text>
                      <SizedBox height={30} />
                      <Text color={Colors.darkGreen} weight={600}>
                        Image
                      </Text>
                      <SizedBox height={10} />
                      <img
                        src={`${data?.result?.imageUrl}`}
                        onError={(e) => {
                          e.currentTarget.src = placeholder;
                        }}
                        alt="advert"
                        style={{ maxWidth: '100%' }}
                      />
                      <SizedBox height={20} />
                    </Card>
                  </Column>
                </Row>
                <SizedBox height={60} />
                <Row childGap={10}>
                  <Column xs={12} lg={4}>
                    <Text size={18} weight="600">
                      Advert Placement
                    </Text>
                    <Text color={Colors.blackGrey}>
                      The page on the mobile app where the advert is placed
                    </Text>
                  </Column>
                  <Column style={{ flex: '1' }} xs={12} lg={8}>
                    <Card fullWidth style={{ padding: '4%' }}>
                      <Text>Ad placement page</Text>
                      <Text color={Colors.blackGrey}>{data?.result?.page}</Text>
                      <SizedBox height={30} />
                      <Text>Page ID</Text>
                      <Text color={Colors.blackGrey}>
                        {data?.result?.pageId}
                      </Text>
                    </Card>
                  </Column>
                </Row>
              </Column>

              <Column style={{ flex: '1' }} xs={12} lg={3}>
                <Card>
                  <Text weight={600}>Sample Ad placement</Text>
                  <SizedBox height={30} />
                  <Iphone />
                </Card>
              </Column>
            </Row>
          )}
        </>
      )}
      {renderModals()}
    </>
  );
};
