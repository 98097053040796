import React, { useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import { Column } from '../../../components/UiKit/Column';
import { SizedBox } from '../../../components/UiKit/SizedBox';
import { Row } from '../../../components/UiKit/Row';
import { Button } from '../../../components/UiKit/Button';
import { TextField } from '../../../components/UiKit/TextField';
import { SimpleTable } from '../../../components/UiKit/Table';
import { Card } from '../../../components/UiKit/Card';
import { IAds } from './interface';
import { useLazyFetch } from '../../../hooks/useRequests';
import { generateShortId } from '../../../utils/generateShortId';
import { Colors } from '../../../themes/colors';
import { Pagination } from '../../../components/UiKit/Pagination';
import { paginationLimits } from '../../../utils/paginationLimits';
import { Text } from '../../../components/UiKit/Text';
import { logger } from '../../../utils/logger';
import { useGetSearchString } from '../../../hooks/useSearch';

export const PlacedAds = () => {
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [nameToSearch, setNameToSearch] = useState('');
  const search = useGetSearchString(nameToSearch);

  const [fetchResults, { data, loading }] = useLazyFetch<IAds>(
    `Mobility.AccountBackoffice/api/Ads/GetAds?pageNumber=${pageNumber}&pageSize=${pageSize}${search}`,
  );

  const placeholder = 'https://via.placeholder.com/350x65?text=No Advert Image';

  const fetchRef = useRef(fetchResults);

  useEffect(() => {
    (async () => {
      try {
        await fetchRef.current();
      } catch (e) {
        logger.log(e);
      }
    })();
  }, []);

  const handleSearch = () => {
    fetchResults();
  };

  useEffect(() => {
    if (data?.result.results.length) {
      const result = data?.result?.results?.map((r, i) =>
        Object.values({
          image: (
            <img
              src={`${r.imageUrl}`}
              onError={(e) => {
                e.currentTarget.src = placeholder;
              }}
              alt={r.imageUrl}
              style={{ maxWidth: '188px', maxHeight: '35px' }}
            />
          ),
          title: <Text>{r.name}</Text>,
          pageDescription: (
            <Text style={{ wordBreak: 'break-word' }}>{r.description}</Text>
          ),
          pageId: r.pageId,
          lastUpdated: DateTime.fromMillis(Date.now(), {
            locale: 'fr',
          }).toLocaleString(),
          action: (
            <Button link color={Colors.darkGreen} key={generateShortId()}>
              View
            </Button>
          ),
        }),
      );

      setAds(result);

      const methods = data?.result?.results?.map((r, i) => () =>
        history.push(`ads/${r.id}`),
      );

      setOnRowClick(methods);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.result.results, history]);

  const [onRowClick, setOnRowClick] = useState<(() => void)[] | (() => void)>();

  const [ads, setAds] = useState<
    (string | number | React.FC | JSX.Element)[][]
  >();

  return (
    <>
      <Row useAppMargin justifyContent="space-between">
        <Column fullHeight useAppMargin xs={12} md={6}>
          <Row useAppMargin>
            {/* <Column useAppMargin xs={4} md={2}>
              <Button variant="default" fullWidth>
                Filter
              </Button>
            </Column> */}
            <Column useAppMargin md={10}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSearch();
                }}
              >
                <TextField
                  placeholder="Search by name"
                  value={nameToSearch}
                  onChange={(e) => setNameToSearch(e.target.value)}
                  rightIcon={<Button>Search</Button>}
                />
              </form>
            </Column>
          </Row>
        </Column>
        <Column useAppMargin xs={12} md={4} lg={2} justifyContent="flex-end">
          <Button onClick={() => history.push('/ads/new')} fullWidth>
            New Advert
          </Button>
        </Column>
      </Row>
      <SizedBox height={24} />
      <Column>
        <Card style={{ padding: '1.5rem' }} fullWidth>
          <SimpleTable
            loading={loading}
            columns={[
              'Image',
              'Ad Name',
              'Description',
              'Page ID',
              'Last Updated',
              'Action',
            ]}
            data={ads}
            onRowClick={onRowClick}
          />

          {!loading && !data?.result?.results?.length ? (
            <Text alignment="center" color={Colors.blackGrey}>
              No adverts have been added yet
            </Text>
          ) : null}

          <Column>
            {data?.result.results && (
              <Row useAppMargin justifyContent="space-between">
                <Column xs={4} md={2}>
                  <TextField
                    leftIcon="Show:"
                    placeholder={`${pageSize}`}
                    dropDown
                    dropDownOptions={paginationLimits}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                  />
                </Column>
                <Column
                  xs={12}
                  md={8}
                  fullHeight
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Pagination
                    breakLabel="..."
                    pageCount={data.result.totalNumberOfPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(e) => setPageNumber(e.selected + 1)}
                    containerClassName="pagination"
                    activeClassName="active"
                  />
                </Column>
              </Row>
            )}
          </Column>
        </Card>
      </Column>
    </>
  );
};
