export interface IReservedSims {
  currentPage: number;
  pageSize: number;
  totalPages: number;
  totalCount: number;
  hasPrevious: boolean;
  hasNext: boolean;
  data: [
    {
      id: 1;
      isReserved: false;
      msisdn: string;
      categoryName: string;
      amount: string;
      reservationCode: string;
      dateUploaded: string;
    },
  ];
}

export interface ICat {
  id: number;
  description: string;
  amount: string;
}

export interface SuccessResp {
  responseCode: number;
  message: string;
}

export const emptyError = {
  responseCode: 0,
  message: '',
};

export interface IError {
  responseCode: number;
  message: string;
}
