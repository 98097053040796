import React from 'react';
import { Colors } from '../../../themes/colors';
import { convertHexToRGBA } from '../../../utils/convertHexToRGBA';
import { Text } from '../Text';

export const Copyright = () => {
  return (
    <Text color={convertHexToRGBA(Colors.blackGrey, 0.6)} alignment="center">
      Copyright © 2020 | 9mobile | All Rights Reserved
    </Text>
  );
};
