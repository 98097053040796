import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ReactComponent as DownloadIcon } from '../../../assets/images/download-icon.svg';
import { ReactComponent as Iphone } from '../../../assets/images/iphone_11.svg';
import { TopBar } from '../../../components/TopBar';
import { BackButton } from '../../../components/UiKit/BackButton';
import { Button } from '../../../components/UiKit/Button';
import { Card } from '../../../components/UiKit/Card';
import { Column } from '../../../components/UiKit/Column';
import { Row } from '../../../components/UiKit/Row';
import { Text } from '../../../components/UiKit/Text';
import { TextField } from '../../../components/UiKit/TextField';
import { useGlobalStore } from '../../../store';
import { getFieldError } from '../../../utils/formikHelper';
import { rem } from '../../../utils/rem';
import { IError, emptyError, ISingleAdd } from './interface';
import { ErrorBox } from '../../../components/UiKit/ErrorBox';
import { logger } from '../../../utils/logger';
import { SizedBox } from '../../../components/UiKit/SizedBox';
import { Modal } from '../../../components/UiKit/Modal';
import { useFetch, usePost } from '../../../hooks/useRequests';
import { IPage } from '../Pages/interface';
import { Spinner } from '../../../components/UiKit/Spinner';
import { Colors } from '../../../themes/colors';
import { Styles } from './styles';

interface SuccessResp {
  responseCode: number;
  message: string;
  result: string;
}

export const EditAdvert = () => {
  const history = useHistory();
  const { id } = useRouteMatch().params as any;

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [updateError, setUpdateError] = useState<IError>(emptyError);
  const [updateAdvertSucess, setUpdateAdvertSuccess] = useState(false);
  const [fileError, setFileError] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File>(new File([''], ''));
  const [imagePreview, setImagePreview] = useState('');

  const [pages, setPages] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const {
    state: {
      auth: { user },
    },
  } = useGlobalStore();

  const placeholder = 'https://via.placeholder.com/350x65?text=No Advert Image';

  const [editAdvert, { error: editAddError, loading: editing }] = usePost<
    Response
  >(`Mobility.AccountBackOffice/api/Ads/EditAd`);

  const { data: adData, loading: loadingAd, error: addError } = useFetch<
    ISingleAdd
  >(`Mobility.AccountBackoffice/api/Ads/GetAd?id=${id}`);

  const { data: pagesData } = useFetch<IPage>(
    `Mobility.AccountBackoffice/api/Ads/GetPagesNameAndId`,
  );

  const [uploadImage, { loading: uploading }] = usePost<SuccessResp>(
    'Mobility.AccountBackOffice/api/Uploads/UploadImage',
  );

  useEffect(() => {
    if (pagesData) {
      const results = pagesData?.result?.map((option) => ({
        label: option.name,
        value: String(option.id),
      }));

      setPages(results);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesData]);

  useEffect(() => {
    if (adData) {
      formik.setErrors({ name: '', description: '', pageId: '' });
      formik.setFieldValue('name', adData?.result?.name);
      formik.setFieldValue('description', adData?.result?.description);
      formik.setFieldValue('pageId', adData?.result?.pageId);

      const imageUrl = `${adData?.result?.imageUrl}`;
      setImagePreview(imageUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adData]);

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      pageId: '',
      id,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Advert name is required').min(4).max(20),
      description: Yup.string()
        .required('Advert description is required')
        .min(4)
        .max(40),
      pageId: Yup.string().required('Please select a page'),
    }),
    onSubmit: () => {
      if (imagePreview !== '') {
        setShowConfirmationModal(true);
      } else {
        setFileError('please select a valid image file');
      }
    },
  });

  const handleAdvertUpdate = async (imageUrl?: string) => {
    try {
      const response = await editAdvert({
        ...formik.values,
        pageId: Number(formik.values.pageId),
        id: Number(formik.values.id),
        imageUrl,
      });
      if (response.data) {
        setShowConfirmationModal(false);
        setUpdateAdvertSuccess(true);
      }
    } catch (errorResp) {
      logger.log(errorResp);
    }
  };

  const handleNewImage = () => {
    if (selectedFile.name !== '') {
      (async () => {
        const data = new FormData();
        data.append('ProfileImage', selectedFile);
        try {
          const response = await uploadImage(data);
          if (response.data) {
            setSelectedFile(new File([''], ''));
            setFileError('');
            // update the photoUrl in global state
            if (response?.data?.result) {
              handleAdvertUpdate(response?.data?.result);
            }
          }
        } catch (errorResp) {
          setFileError((errorResp as Error).message);
        }
      })();
    } else {
      handleAdvertUpdate();
    }
  };

  useEffect(() => {
    if (editAddError) {
      setUpdateError(editAddError);
    }
  }, [editAddError]);

  const UpdateError = updateError?.message && (
    <ErrorBox>{updateError?.message}</ErrorBox>
  );

  const isFileImage = (file: File) => {
    const acceptedImageTypes = ['image/jpeg', 'image/png'];

    return file && acceptedImageTypes.includes(file.type);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files instanceof FileList) {
      if (isFileImage(event.target.files[0])) {
        const file = event.target.files[0];
        const fileSize = parseFloat(`${file.size / (1024 * 1024)}`).toFixed(2);

        if (Number(fileSize) > 1) {
          setFileError('Please select image size less than 1 MB');
        } else {
          const img = new Image();

          img.src = window.URL.createObjectURL(file);

          img.onload = () => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;

            if (width <= 375 && height <= 76) {
              // ok
              setFileError('');
              setImagePreview(URL.createObjectURL(file));

              setSelectedFile(file);
            } else {
              // error
              setFileError('Image must have width <= 375 and height <= 76');
            }
          };
        }
      } else {
        setFileError('Please select a valid JPEG or PNG file');
      }
    } else {
      setFileError('invalid file selected');
    }
  };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const handleClick = () => {
    if (hiddenFileInput.current !== null) {
      hiddenFileInput && hiddenFileInput.current.click();
    }
  };

  const renderModals = () => (
    <>
      <Modal
        isVisible={showConfirmationModal}
        onClose={() => {
          setShowConfirmationModal(false);
          setUpdateError(emptyError);
        }}
        header={{ title: 'Confirmation' }}
        size="sm"
      >
        {UpdateError}

        <SizedBox height={15} />
        <Column>
          <Text>Hi {user?.name},</Text>
          <SizedBox height={15} />
          <Text>Please confirm advert update</Text>
          <SizedBox height={10} />
          <Row useAppMargin>
            <Column xs={6} useAppMargin>
              <Button
                onClick={() => handleNewImage()}
                isLoading={editing || uploading}
                fullWidth
              >
                Confirm
              </Button>
            </Column>
            <Column xs={6} useAppMargin>
              <Button
                onClick={() => {
                  setShowConfirmationModal(false);
                  setUpdateError(emptyError);
                }}
                outline
                fullWidth
                variant="tertiary"
              >
                Cancel
              </Button>
            </Column>
          </Row>
        </Column>
      </Modal>

      <Modal
        isVisible={updateAdvertSucess}
        onClose={() => {
          setUpdateAdvertSuccess(false);
          history.push('/ads');
        }}
        size="sm"
      >
        <SizedBox height={15} />
        <Column>
          <Text>Hi {user?.name},</Text>
          <SizedBox height={15} />
          <Text>Advert updated successfully</Text>
          <SizedBox height={10} />
          <Button
            onClick={() => {
              setUpdateAdvertSuccess(false);
              history.push('/ads');
            }}
            fullWidth
          >
            Done
          </Button>
        </Column>
      </Modal>
    </>
  );

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <TopBar name="User Administration" />
        <Card fullWidth style={{ padding: '1.5% 4%', boxShadow: 'none' }}>
          <Row justifyContent="space-between">
            <BackButton>
              <Text size={18} weight={600}>
                Edit Advert
              </Text>
            </BackButton>
            <Button type="submit">Update Advert</Button>
          </Row>
        </Card>

        {loadingAd ? (
          <Card fullWidth fullHeight>
            <Spinner isFixed>Fetching advert details</Spinner>
          </Card>
        ) : (
          <>
            {addError ? (
              <Card fullWidth fullHeight>
                <ErrorBox>{addError.message}</ErrorBox>
              </Card>
            ) : (
              <Row childGap={10} style={{ padding: rem(40) }}>
                <Column xs={12} md={6}>
                  <Card fullWidth>
                    <TextField
                      label="name"
                      placeholder="Enter Page name"
                      {...formik.getFieldProps('name')}
                      type="text"
                      error={getFieldError(
                        formik.errors.name,
                        formik.touched.name,
                      )}
                      minLength={4}
                      maxLength={20}
                    />

                    <TextField
                      label="description"
                      placeholder="Enter Page description"
                      {...formik.getFieldProps('description')}
                      type="text"
                      multiline
                      as="textarea"
                      error={getFieldError(
                        formik.errors.description,
                        formik.touched.description,
                      )}
                      minLength={4}
                      maxLength={40}
                    />

                    <SizedBox height={10} />

                    <Text>
                      Image Upload
                      <Text color={Colors.blackGrey}>
                        (dimensions must be 375 X 76)
                      </Text>
                    </Text>
                    <SizedBox height={10} />
                    <Column
                      alignItems="center"
                      justifyContent="center"
                      onClick={handleClick}
                      style={{
                        border: `dotted 2px ${Colors.grey}`,
                        padding: imagePreview !== '' ? '0px' : rem(30),
                        borderRadius: '5px',
                      }}
                    >
                      {imagePreview !== '' ? (
                        <Column
                          onClick={handleClick}
                          style={{ position: 'relative' }}
                        >
                          <Styles.CloseButton
                            onClick={() => {
                              setImagePreview('');
                              setSelectedFile(new File([''], ''));
                            }}
                          >
                            x
                          </Styles.CloseButton>
                          <img
                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                            src={imagePreview}
                            onError={(e) => {
                              e.currentTarget.src = placeholder;
                            }}
                            alt="ad"
                          />
                        </Column>
                      ) : (
                        <>
                          <DownloadIcon />
                          <Text>Click to browse</Text>
                          <input
                            type="file"
                            ref={hiddenFileInput}
                            onChange={handleChange}
                            style={{ display: 'none' }}
                          />
                        </>
                      )}
                    </Column>
                    {fileError && (
                      <Text color={Colors.orange}>{fileError.toString()}</Text>
                    )}
                    <SizedBox height={20} />

                    <TextField
                      label="Select Page"
                      placeholder="Select Page"
                      dropDown
                      dropDownOptions={pages}
                      value={formik.values.pageId}
                      defaultValue={adData?.result?.page}
                      onChange={(e) =>
                        formik.setFieldValue('pageId', e.target.value)
                      }
                      type="text"
                      error={getFieldError(
                        formik.errors.pageId,
                        formik.touched.pageId,
                      )}
                    />
                  </Card>
                </Column>
                <Column xs={12} md={3}>
                  <Card fullWidth>
                    <Text weight={600}>Sample Ad placement</Text>
                    <SizedBox height={30} />
                    <Column justifyContent="center">
                      <Iphone />
                    </Column>
                  </Card>
                </Column>
              </Row>
            )}
          </>
        )}
      </form>

      {renderModals()}
    </>
  );
};
