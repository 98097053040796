// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
// import { FaWallet } from 'react-icons/fa';

// import { DateTime } from 'luxon';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DateTime } from 'luxon';
import { Card } from '../../components/UiKit/Card';
import { PageBody } from '../../components/UiKit/PageBody';
import { Column } from '../../components/UiKit/Column';
import { SizedBox } from '../../components/UiKit/SizedBox';
import { Row } from '../../components/UiKit/Row';
import { TopBar } from '../../components/TopBar';
import { Button } from '../../components/UiKit/Button';
import { TextField } from '../../components/UiKit/TextField';
import { SimpleTable } from '../../components/UiKit/Table';

import { SingleNotification } from './interface';
import { useFetch, usePost } from '../../hooks/useRequests';
// import { Pagination } from '../../components/UiKit/Pagination';
// import { paginationLimits } from '../../utils/paginationLimits';
// import { useGetSearchMobile } from '../../hooks/useSearch';
import { Drawer } from '../../components/RightDrawer';
import { Text } from '../../components/UiKit/Text';
// import { logger } from '../../utils/logger';
import { ErrorBox } from '../../components/UiKit/ErrorBox';
import { getFieldError } from '../../utils/formikHelper';
import { SuccessBox } from '../../components/UiKit/SuccessBox';
import { Colors } from '../../themes/colors';
import { generateShortId } from '../../utils/generateShortId';
import { Modal } from '../../components/UiKit/Modal';
import { useGlobalStore } from '../../store';
import { logger } from '../../utils/logger';

export const emptyError = {
  responseCode: 0,
  message: '',
};

export interface IError {
  responseCode: number;
  message: string;
}

export const Notififcations = () => {
  // const [pageNumber, setPageNumber] = useState(1);
  // const [pageSize, setPageSize] = useState(10);

  // const [searchMobile, setSearchMobile] = useState('');
  // const search = useGetSearchMobile(searchMobile);
  const [updateError, setUpdateError] = useState<IError>(emptyError);

  const {
    state: {
      auth: { user },
    },
  } = useGlobalStore();

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const url = `Mobility.Notifications/api/Notification/GetAllNotification`;
  const { data, loading, refetch } = useFetch<SingleNotification[]>(url);

  const [sendNotification, { error, loading: loadingSend }] = usePost<{
    message: string;
  }>(`Mobility.AccountBackoffice/api/Notifications/PostNotification`);

  const [
    deleteNotification,
    { error: deleteErr, loading: deleting },
  ] = usePost<{
    message: string;
  }>(`Mobility.Notifications/api/Notification/RemoveNotification`);

  const [notificationData, setNotificationData] = useState<
    (string | number | React.FC | JSX.Element)[][]
  >();

  const [showDrawer, setShowDrawer] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [successErrorMessage, setSuccessErrorMessage] = useState('');
  const [notificationId, setNotificationId] = useState('');

  useEffect(() => {
    if (error?.message) {
      setSuccessErrorMessage(error.message);
    }
  }, [error]);

  const formik = useFormik({
    initialValues: {
      title: '',
      message: '',
      notificationType: 'Advert',
      endDate: '',
      // recipient: '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required('title is required'),
      message: Yup.string().required('message is required'),
      endDate: Yup.string().required('end date is required'),
    }),
    onSubmit: async (values) => {
      await sendNotification(values);
      setSuccessMessage('Message sent Successfully');
      formik.resetForm();
      refetch();
    },
  });

  const handleDelete = async () => {
    try {
      const response = await deleteNotification({
        notificationId,
      });
      if (response.data) {
        setDeleteConfirmation(false);
        refetch();
      }
    } catch (errorResp) {
      logger.log(errorResp);
    }
  };

  useEffect(() => {
    if (showDrawer) {
      setSuccessMessage('');
      setSuccessErrorMessage('');
    }
  }, [showDrawer]);

  useEffect(() => {
    if (deleteErr) {
      setUpdateError(deleteErr);
    }
  }, [deleteErr]);

  const UpdateError = updateError?.message && (
    <ErrorBox>{updateError?.message}</ErrorBox>
  );

  useEffect(() => {
    if (data) {
      // columns={['Title', 'Message', 'Date', 'Sent to', 'Read by']}
      const result = data.map((r, i) =>
        Object.values({
          Title: <Text style={{ wordBreak: 'break-word' }}>{r.title}</Text>,
          Message: <Text style={{ wordBreak: 'break-word' }}>{r.message}</Text>,
          // Date: DateTime.fromISO(r.date, {
          //   locale: 'ng',
          // }).toLocaleString(DateTime.DATETIME_MED),
          // sentTo: r.sentTo,
          // readBy: r.readBy,
          expires: DateTime.fromISO(r.expiryDate, {
            locale: 'ng',
          }).toLocaleString(DateTime.DATETIME_MED),
          action: (
            <Button
              link
              color={Colors.darkGreen}
              key={generateShortId()}
              onClick={() => {
                setDeleteConfirmation(true);
                setNotificationId(r.id);
              }}
            >
              Delete
            </Button>
          ),
        }),
      );

      setNotificationData(result);
    }
  }, [data]);

  const renderModals = () => (
    <>
      <Modal
        isVisible={deleteConfirmation}
        onClose={() => {
          setDeleteConfirmation(false);
        }}
        header={{ title: 'Confirmation' }}
        size="sm"
      >
        {UpdateError}

        <SizedBox height={15} />
        <Column>
          <Text>Hi {user?.name},</Text>
          <SizedBox height={15} />
          <Text>Please confirm advert status change</Text>
          <SizedBox height={10} />
          <Row useAppMargin>
            <Column xs={6} useAppMargin>
              <Button
                onClick={() => handleDelete()}
                isLoading={deleting}
                fullWidth
              >
                Confirm
              </Button>
            </Column>
            <Column xs={6} useAppMargin>
              <Button
                onClick={() => {
                  setDeleteConfirmation(false);
                }}
                outline
                fullWidth
                variant="tertiary"
              >
                Cancel
              </Button>
            </Column>
          </Row>
        </Column>
      </Modal>
    </>
  );

  return (
    <>
      <TopBar name="Messaging" />

      <Drawer showDrawer={showDrawer} setShowDrawer={setShowDrawer}>
        <Text size={18} weight={600}>
          New Message
        </Text>
        <SizedBox height={50} />
        <form onSubmit={formik.handleSubmit}>
          {successErrorMessage && <ErrorBox>{successErrorMessage}</ErrorBox>}
          {successMessage && <SuccessBox>{successMessage}</SuccessBox>}

          <TextField
            label="Message title"
            placeholder="Enter message title"
            {...formik.getFieldProps('title')}
            error={getFieldError(formik.errors.title, formik.touched.title)}
            type="text"
          />

          {/* <TextField
            label="Recipient Type"
            placeholder="Enter recipient type"
            {...formik.getFieldProps('type')}
            type="text"
            dropDown
            dropDownOptions={[
              { label: 'Prepaid Users', value: 'Prepaid Users' },
              { label: 'Postpaid Users', value: 'Postpaid Users' },
            ]}
          /> */}

          <TextField
            label="End Date"
            placeholder="Enter end date"
            {...formik.getFieldProps('endDate')}
            error={getFieldError(formik.errors.endDate, formik.touched.endDate)}
            type="date"
          />

          <TextField
            label="Message"
            placeholder="Enter message"
            {...formik.getFieldProps('message')}
            error={getFieldError(formik.errors.message, formik.touched.message)}
            type="text"
            multiline
            as="textarea"
            rows={5}
          />
          <SizedBox height={40} />

          <Row useAppMargin>
            <Column useAppMargin xs={6}>
              <Button
                variant="default"
                fullWidth
                onClick={() => setShowDrawer(false)}
                outline
              >
                Cancel
              </Button>
            </Column>

            <Column useAppMargin xs={6}>
              <Button isLoading={loadingSend} type="submit" fullWidth>
                Send Message
              </Button>
            </Column>
          </Row>
        </form>
      </Drawer>
      <PageBody>
        <Row useAppMargin justifyContent="space-between">
          <Column fullHeight useAppMargin xs={12} md={6}>
            <Row useAppMargin>
              {/* <Column useAppMargin xs={4} md={2}>
                <Button variant="default" fullWidth>
                  Filter
                </Button>
              </Column> */}
              {/* <Column useAppMargin xs={6} md={8}>
                <TextField
                  placeholder="Search by mobile"
                  // onChange={(e) => setSearchMobile(e.target.value)}
                />
              </Column> */}
            </Row>
          </Column>
          <Column useAppMargin xs={12} md={4} lg={2} justifyContent="flex-end">
            <Button fullWidth onClick={() => setShowDrawer(true)}>
              New Message
            </Button>
          </Column>
        </Row>
        <SizedBox height={24} />
        <Column>
          <Card style={{ padding: '1.5rem' }} fullWidth>
            <SimpleTable
              columns={[
                'Notification Title',
                'Message Content',
                'Expires',
                // 'Date Sent',
                // 'Sent to',
                // 'Read by',
                '',
              ]}
              data={notificationData}
              loading={loading}
            />

            {data?.length === 0 && 'No notifications at the moment'}

            <SizedBox height={20} />
          </Card>
        </Column>
      </PageBody>
      {renderModals()}
    </>
  );
};
