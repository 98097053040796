export interface IPages {
  result: {
    pageNumber: number;
    pageSize: number;
    totalNumberOfPages: number;
    totalNumberOfRecords: number;
    nextPageUrl: null;
    prevPageUrl: null;
    results: [
      {
        name: string;
        description: string;
        imageUrl: string;
        createdBy: string;
        createdDate: string;
        id: number;
        noOfAds: number;
        pageId: string;
      },
    ];
  };
  responseCode: number;
  message: string;
}

export interface IError {
  responseCode: number;
  message: string;
}

export const emptyError = {
  responseCode: 0,
  message: '',
};

export interface IPage {
  result: [
    {
      id: number;
      name: string;
    },
  ];
  responseCode: number;
  message: string;
}
