// eslint-disable-next-line
import React, { useEffect, useRef, useState } from 'react';

import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import { Card } from '../../components/UiKit/Card';
import { PageBody } from '../../components/UiKit/PageBody';
import { Column } from '../../components/UiKit/Column';
import { SizedBox } from '../../components/UiKit/SizedBox';
import { Row } from '../../components/UiKit/Row';
import { TopBar } from '../../components/TopBar';
import { Button } from '../../components/UiKit/Button';
import { TextField } from '../../components/UiKit/TextField';
import { SimpleTable } from '../../components/UiKit/Table';
import { useLazyFetch, usePost } from '../../hooks/useRequests';
import { IRoaming, SuccessResp } from './interface';
import { paginationLimits } from '../../utils/paginationLimits';
import { Pagination } from '../../components/UiKit/Pagination';
import { DownloadLink } from '../../components/UiKit/DownloadLink';
import { exportToExcel } from '../../utils/exportToExcel';
import { logger } from '../../utils/logger';
import { Drawer } from '../../components/RightDrawer';
import { Text } from '../../components/UiKit/Text';
import { Colors } from '../../themes/colors';
import { Styles } from '../SimReservation/styles';
import { ErrorBox } from '../../components/UiKit/ErrorBox';
import { Modal } from '../../components/UiKit/Modal';
import { useGlobalStore } from '../../store';
import { Spinner } from '../../components/UiKit/Spinner';
import { ReactComponent as DropzoneIcon } from '../../assets/images/dropzone-icon.svg';

export const Roaming = () => {
  const {
    state: {
      auth: { user },
    },
  } = useGlobalStore();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [showDrawer, setShowDrawer] = useState(false);
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({});

  const [parsedData, setParsedData] = useState<unknown[][]>();
  const [isParsing, setIsParsing] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const url = `Mobility.AccountBackoffice/api/RoamingRates/Get?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  const [fetchResults, { data, loading }] = useLazyFetch<IRoaming>(url);

  const [roamingRates, setRoamingRates] = useState<
    (string | number | React.FC | JSX.Element)[][]
  >();

  const [uploadSimsCsv, { loading: uploading, error: uploadErr }] = usePost<
    SuccessResp
  >('Mobility.AccountBackOffice/api/RoamingRates/RoamingRates');

  useEffect(() => {
    if (acceptedFiles.length) {
      setIsParsing(true);
      const csvData: unknown[][] = [];
      Papa.parse(acceptedFiles[0], {
        header: true,
        skipEmptyLines: true,
        step(result) {
          // csvData.push({ ...result.data });
        },
        complete() {
          setIsParsing(false);
          setParsedData(csvData);
        },
      });
    }
  }, [acceptedFiles]);

  const handleCsvUpload = async () => {
    try {
      const response = await uploadSimsCsv(parsedData);
      if (response.data) {
        setShowDrawer(false);
        setShowSuccessModal(true);
      }
    } catch (errorResp) {
      logger.log(errorResp);
    }
  };

  const fetchRef = useRef(fetchResults);

  useEffect(() => {
    (async () => {
      try {
        await fetchRef.current();
      } catch (e) {
        logger.log(e);
      }
    })();
  }, []);

  useEffect(() => {
    if (data?.result?.results?.length) {
      const result = data.result?.results.map((r, i) =>
        Object.values({
          serialNumber: r.id,
          country: r.country,
          operator: r.operator,
          callRateToNigeria: r.callRateToNigeria,
          callRateWithinLocation: r.callRateWithinLocation,
          smsRate: r.smsRate,
          receivingCallRate: r.receivingCallRate,
        }),
      );

      setRoamingRates(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.result?.results]);

  const renderModals = () => (
    <>
      <Modal
        isVisible={showSuccessModal}
        // onClose={() => history.push('/login')}
        size="sm"
      >
        <SizedBox height={15} />
        <Column>
          <Text>Hi {user?.name},</Text>
          <SizedBox height={15} />
          <Text>Roaming data uploaded successfully</Text>
          <SizedBox height={10} />
          <Button
            onClick={() => {
              setShowSuccessModal(false);
              fetchResults();
              setParsedData(undefined);
            }}
            fullWidth
          >
            Done
          </Button>
        </Column>
      </Modal>
    </>
  );

  return (
    <>
      <TopBar name="Roaming" />
      <PageBody>
        <Row useAppMargin justifyContent="flex-end">
          <Button onClick={() => setShowDrawer(true)}>Upload CSV</Button>
          <SizedBox width={40} />

          <Button
            onClick={() =>
              exportToExcel(`${url}&exportToExcel=true`, 'RoamingRates')
            }
            outline
            variant="secondary"
          >
            Export CSV
          </Button>
        </Row>
        <SizedBox height={40} />
        <Column>
          <Card style={{ padding: '1.5rem' }} fullWidth>
            <SimpleTable
              columns={[
                'S//N',
                'Country',
                'Roaming Ntw. Name',
                'Calling Nigeria',
                'Calling Local No.',
                'Texting Local No.',
                'Receive Local call',
              ]}
              data={roamingRates}
              loading={loading}
              scrollable
            />
            <SizedBox height={20} />

            {data?.result.results && (
              <Row useAppMargin justifyContent="space-between">
                <Column xs={4} md={2}>
                  <TextField
                    leftIcon="Show:"
                    placeholder={`${pageSize}`}
                    dropDown
                    dropDownOptions={paginationLimits}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                  />
                </Column>
                <Column
                  xs={12}
                  md={8}
                  fullHeight
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Pagination
                    breakLabel="..."
                    pageCount={data.result.totalNumberOfPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(e) => setPageNumber(e.selected + 1)}
                    containerClassName="pagination"
                    activeClassName="active"
                  />
                </Column>
              </Row>
            )}
          </Card>
        </Column>
      </PageBody>

      <Drawer showDrawer={showDrawer} setShowDrawer={setShowDrawer}>
        <Text weight={600}>Upload New File</Text>
        <SizedBox height={50} />
        <DownloadLink url="Roaming template.csv">
          <Button link type="submit">
            Download Template
          </Button>
        </DownloadLink>
        <SizedBox height={50} />

        <Styles.DropContainer {...getRootProps({})}>
          {isParsing ? (
            <Spinner />
          ) : (
            <>
              {parsedData?.length ? (
                <>
                  <Text>processed {parsedData.length} records</Text>
                  <Text>Click on upload to proceed</Text>
                  <SizedBox height={20} />
                  <Text
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => setParsedData(undefined)}
                  >
                    Clear
                  </Text>
                </>
              ) : (
                <>
                  <input {...getInputProps()} />
                  <DropzoneIcon />
                  <SizedBox height={30} />
                  <Text weight={600} color={Colors.black}>
                    Drag your files to upload
                  </Text>
                  <SizedBox height={5} />
                  <Text>or click to browse</Text>
                </>
              )}
            </>
          )}
        </Styles.DropContainer>
        {uploadErr && <ErrorBox>{uploadErr.message}</ErrorBox>}

        <SizedBox height={20} />

        <Row justifyContent="space-between" childGap={10}>
          <Column xs={12} md={6} style={{ flex: '1' }}>
            <Button
              fullWidth
              outline
              variant="tertiary"
              onClick={() => setShowDrawer(false)}
            >
              Cancel
            </Button>
          </Column>
          <Column xs={12} md={6} style={{ flex: '1' }}>
            <Button
              disabled={!acceptedFiles[0] || uploading}
              fullWidth
              variant="primary"
              onClick={handleCsvUpload}
              isLoading={uploading}
            >
              Upload
            </Button>
          </Column>
        </Row>
      </Drawer>
      {renderModals()}
    </>
  );
};
