// eslint-disable-next-line
import React, { useState } from 'react';

import { Card } from '../../components/UiKit/Card';
import { PageBody } from '../../components/UiKit/PageBody';
import { Row } from '../../components/UiKit/Row';
import { TopBar } from '../../components/TopBar';
import { Text } from '../../components/UiKit/Text';
import { Colors } from '../../themes/colors';
import { Users } from './Users';
import { Roles } from './Roles';
import { NewUser } from './NewUser';
import { NewRole } from './NewRole';
import { ViewRole } from './ViewRole';
import { SingleRole, SingleUser } from './interface';
import { Modal } from '../../components/UiKit/Modal';
import { SizedBox } from '../../components/UiKit/SizedBox';
import { Column } from '../../components/UiKit/Column';
import { Button } from '../../components/UiKit/Button';
import { useGlobalStore } from '../../store';
import { EditUser } from './EditUser';

export const UserAdministration = () => {
  const {
    state: {
      auth: { user },
    },
  } = useGlobalStore();

  const [activeTab, setActiveTab] = useState(1);

  const [showDrawer, setShowDrawer] = useState(false);

  const [showEditDrawer, setShowEditDrawer] = useState(false);

  const [editUserSuccess, setEditUserSuccess] = useState(false);

  const [showRoleDrawer, setShowRoleDrawer] = useState(false);

  const [showViewRoleDrawer, setShowViewRoleDrawer] = useState(false);

  const [newUserSuccess, setNewUserSuccess] = useState(false);

  const [newRoleSuccess, setNewRoleSuccess] = useState(false);

  const [roleToEdit, setRoleToEdit] = useState<SingleRole>();

  const [userToEdit, setUserToEdit] = useState<SingleUser>();

  const [roleToView, setRoleToView] = useState<SingleRole>();

  return (
    <>
      <TopBar name="User Administration" />
      <Card fullWidth style={{ padding: '0 4%' }}>
        <Row childGap={15}>
          <Text
            style={{
              cursor: 'pointer',
              borderBottom: `solid 5px ${
                activeTab === 1 ? Colors.darkGreen : Colors.white
              }`,
              padding: `20px 15px`,
            }}
            onClick={() => setActiveTab(1)}
          >
            Users
          </Text>
          <Text
            style={{
              cursor: 'pointer',
              borderBottom: `solid 5px ${
                activeTab === 2 ? Colors.darkGreen : Colors.white
              }`,
              padding: `20px 15px`,
            }}
            onClick={() => setActiveTab(2)}
          >
            Roles
          </Text>
        </Row>
      </Card>
      <PageBody>
        {activeTab === 1 ? (
          <Users
            newUserSuccess={newUserSuccess}
            setShowDrawer={setShowDrawer}
            setShowEditDrawer={setShowEditDrawer}
            editUserSuccess={editUserSuccess}
            setUserToEdit={setUserToEdit}
          />
        ) : (
          <Roles
            newRoleSuccess={newRoleSuccess}
            setShowRoleDrawer={setShowRoleDrawer}
            setRoleToEdit={setRoleToEdit}
            setRoleToView={setRoleToView}
            setShowViewRoleDrawer={setShowViewRoleDrawer}
          />
        )}
      </PageBody>
      <NewUser
        showDrawer={showDrawer}
        setNewUserSuccess={setNewUserSuccess}
        newUserSuccess={newUserSuccess}
        setShowDrawer={setShowDrawer}
        // userToEdit={userToEdit}  showEditDrawer, setShowEditDrawer,editUserSuccess, setEditUserSuccess, ,
      />
      <EditUser
        showEditDrawer={showEditDrawer}
        setEditUserSuccess={setEditUserSuccess}
        editUserSuccess={editUserSuccess}
        setShowEditDrawer={setShowEditDrawer}
        userToEdit={userToEdit}
      />
      {}
      <NewRole
        showRoleDrawer={showRoleDrawer}
        setNewRoleSuccess={setNewRoleSuccess}
        setShowRoleDrawer={setShowRoleDrawer}
        roleToEdit={roleToEdit}
      />

      <ViewRole
        setShowViewRoleDrawer={setShowViewRoleDrawer}
        showViewRoleDrawer={showViewRoleDrawer}
        roleToView={roleToView}
      />

      <Modal
        isVisible={newRoleSuccess}
        // onClose={() => history.push('/login')}
        size="sm"
      >
        <SizedBox height={15} />
        <Column>
          <Text>Hi {user?.name},</Text>
          <SizedBox height={15} />
          <Text>
            {roleToEdit ? 'Role updated' : 'New role added'} successfully
          </Text>
          <SizedBox height={10} />
          <Button
            onClick={() => {
              setShowRoleDrawer(false);
              setRoleToEdit(undefined);
              setNewRoleSuccess(false);
            }}
            fullWidth
          >
            Done
          </Button>
        </Column>
      </Modal>
    </>
  );
};
