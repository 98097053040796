import styled from 'styled-components';
import { Colors } from '../../themes/colors';

const DropContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${Colors.grey};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const Styles = {
  DropContainer,
};

export { Styles };
