// eslint-disable-next-line
import React, { useState } from 'react';
// import { FaWallet } from 'react-icons/fa';

import { Card } from '../../components/UiKit/Card';
import { PageBody } from '../../components/UiKit/PageBody';
import { Row } from '../../components/UiKit/Row';
import { TopBar } from '../../components/TopBar';
import { Colors } from '../../themes/colors';

import { Text } from '../../components/UiKit/Text';
import { PlacedAds } from './PlacedAds';
import { Pages } from './Pages';
import { NewPage } from './Pages/NewPage';
import { Modal } from '../../components/UiKit/Modal';
import { SizedBox } from '../../components/UiKit/SizedBox';
import { Column } from '../../components/UiKit/Column';
import { Button } from '../../components/UiKit/Button';
import { useGlobalStore } from '../../store';

export const Ads = () => {
  const [activeTab, setActiveTab] = useState(1);

  // pages state
  const [showNewPageDrawer, setShowNewPageDrawer] = useState(false);
  const [newPageSuccess, setNewPageSuccess] = useState(false);
  const [shouldReloadPages, setShouldReloadPages] = useState(false);

  const {
    state: {
      auth: { user },
    },
  } = useGlobalStore();

  return (
    <>
      <TopBar name="Ads" />
      <Card fullWidth style={{ padding: '0 4%' }}>
        <Row childGap={15}>
          <Text
            style={{
              cursor: 'pointer',
              borderBottom: `solid 5px ${
                activeTab === 1 ? Colors.darkGreen : Colors.white
              }`,
              padding: `20px 15px`,
            }}
            onClick={() => setActiveTab(1)}
          >
            Placed Ads
          </Text>
          <Text
            style={{
              cursor: 'pointer',
              borderBottom: `solid 5px ${
                activeTab === 2 ? Colors.darkGreen : Colors.white
              }`,
              padding: `20px 15px`,
            }}
            onClick={() => setActiveTab(2)}
          >
            Pages
          </Text>
        </Row>
      </Card>
      <PageBody>
        {activeTab === 1 ? (
          <PlacedAds />
        ) : (
          <Pages
            shouldReloadPages={shouldReloadPages}
            setShouldReloadPages={setShouldReloadPages}
            setShowNewPageDrawer={setShowNewPageDrawer}
          />
        )}
      </PageBody>
      {showNewPageDrawer && (
        <NewPage
          setShowNewPageDrawer={setShowNewPageDrawer}
          showNewPageDrawer={showNewPageDrawer}
          setNewPageSuccess={setNewPageSuccess}
        />
      )}

      {newPageSuccess && (
        <Modal
          isVisible={newPageSuccess}
          // onClose={() => history.push('/login')}
          size="sm"
        >
          <SizedBox height={15} />
          <Column>
            <Text>Hi {user?.name},</Text>
            <SizedBox height={15} />
            <Text>New page created successfully</Text>
            <SizedBox height={10} />
            <Button
              onClick={() => {
                setShowNewPageDrawer(false);
                setNewPageSuccess(false);
                setShouldReloadPages(true);
              }}
              fullWidth
            >
              Done
            </Button>
          </Column>
        </Modal>
      )}
    </>
  );
};
