// eslint-disable-next-line
import React, { useEffect, useRef, useState } from 'react';
// import { FaWallet } from 'react-icons/fa';

import { DateTime } from 'luxon';
import { Card } from '../../components/UiKit/Card';
import { PageBody } from '../../components/UiKit/PageBody';
import { Column } from '../../components/UiKit/Column';
import { SizedBox } from '../../components/UiKit/SizedBox';
import { Row } from '../../components/UiKit/Row';
import { TopBar } from '../../components/TopBar';
import { TextField } from '../../components/UiKit/TextField';
import { SimpleTable } from '../../components/UiKit/Table';
import { generateShortId } from '../../utils/generateShortId';
import { Colors } from '../../themes/colors';
import { Text } from '../../components/UiKit/Text';
import { useLazyFetch } from '../../hooks/useRequests';
import { IPrepaidPackage, ISinglePackage } from './interface';
import { useGetSearchString } from '../../hooks/useSearch';
import { logger } from '../../utils/logger';
import { Button } from '../../components/UiKit/Button';
import { EditPrepaidPlan } from './EditPrepaidPlan';

export const PrepaidPackage = () => {
  const [pageNumber] = useState(1);
  const [pageSize] = useState(25);
  const [showEditPage, setShowEditPage] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<ISinglePackage>();

  const [nameToSearch, setNameToSearch] = useState('');

  const search = useGetSearchString(nameToSearch);

  const [fetchResults, { data, loading }] = useLazyFetch<IPrepaidPackage>(
    `Mobility.AccountBackoffice/api/PrepaidPlans/Get?pageNumber=${pageNumber}&pageSize=${pageSize}${search}`,
  );

  const [prepaidPackages, setPrepaidPackages] =
    useState<(string | number | React.FC | JSX.Element)[][]>();

  const fetchRef = useRef(fetchResults);

  useEffect(() => {
    (async () => {
      try {
        await fetchRef.current();
      } catch (e) {
        logger.log(e);
      }
    })();
  }, []);

  const handleSearch = () => {
    fetchResults();
  };

  useEffect(() => {
    if (data?.result.results.length) {
      const result = data?.result?.results?.map((r, i) =>
        Object.values({
          planeName: (
            <Row useAppMargin key={generateShortId()} alignItems="center">
              {/* <Column useAppMargin xs={4} md={2}>
                <Card color={Colors.lightBlue} fullWidth />
              </Column> */}
              <Column useAppMargin xs={8} md={10}>
                <Text color={Colors.lightGreen}>{r.name}</Text>
                <Text>{r.description}</Text>
              </Column>
            </Row>
          ),
          lastUpdated: DateTime.fromMillis(Date.now(), {
            locale: 'fr',
          }).toLocaleString(),
          view: (
            <Button
              size="small"
              outline
              key={generateShortId()}
              onClick={() => {
                setShowEditPage(true);
                setSelectedPackage(r);
              }}
            >
              Edit
            </Button>
          ),
        }),
      );

      setPrepaidPackages(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.result.results]);

  if (showEditPage && selectedPackage) {
    return (
      <EditPrepaidPlan
        selectedPackage={selectedPackage}
        setShowEditPage={setShowEditPage}
        setSelectedPackage={setSelectedPackage}
        fetchResults={fetchResults}
      />
    );
  }
  return (
    <>
      <TopBar name="Prepaid Package" />
      <PageBody>
        <Row useAppMargin justifyContent="space-between">
          <Column fullHeight useAppMargin xs={12} md={6}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (nameToSearch.length === 0) return;
                handleSearch();
              }}
            >
              <Row useAppMargin>
                <Column useAppMargin md={8}>
                  <TextField
                    placeholder="Search Packages"
                    value={nameToSearch}
                    onChange={(e) => setNameToSearch(e.target.value)}
                    rightIcon={<Button>Search</Button>}
                  />
                </Column>
              </Row>
            </form>
          </Column>
        </Row>
        <SizedBox height={24} />
        <Column>
          <Card style={{ padding: '1.5rem' }} fullWidth>
            <SimpleTable
              columns={['Plane Name', 'Last Updated']}
              data={prepaidPackages}
              loading={loading}
            />
          </Card>
        </Column>
      </PageBody>
    </>
  );
};
