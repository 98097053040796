export interface IAds {
  result: {
    pageNumber: number;
    pageSize: number;
    totalNumberOfPages: number;
    totalNumberOfRecords: number;
    nextPageUrl: string;
    prevPageUrl: string | null;
    responseCode: number;
    message: string;
    results: {
      id: number;
      name: string;
      description: string;
      imageUrl: string;
      createdDate: string;
      createdBy: string;
      pageId: number;
      isActive: boolean;
    }[];
  };
}

export interface ISingleAdd {
  responseCode: number;
  message: string;
  result: {
    name: string;
    description: string;
    id: number;
    pageId: number;
    imageUrl: string;
    isActive: true;
    page: string;
    dateCreated: string;
    createdBy: string;
  };
}

export interface IError {
  responseCode: number;
  message: string;
}

export const emptyError = {
  responseCode: 0,
  message: '',
};
