import { DateTime } from 'luxon';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Button } from '../../../components/UiKit/Button';
import { Card } from '../../../components/UiKit/Card';
import { Column } from '../../../components/UiKit/Column';
import { Pagination } from '../../../components/UiKit/Pagination';
import { Row } from '../../../components/UiKit/Row';
import { SizedBox } from '../../../components/UiKit/SizedBox';
import { SimpleTable } from '../../../components/UiKit/Table';
import { Text } from '../../../components/UiKit/Text';
import { TextField } from '../../../components/UiKit/TextField';
import { useLazyFetch } from '../../../hooks/useRequests';
import { useGetSearchString } from '../../../hooks/useSearch';
import { Colors } from '../../../themes/colors';
// import { Colors } from '../../../themes/colors';
// import { generateShortId } from '../../../utils/generateShortId';
import { logger } from '../../../utils/logger';
import { paginationLimits } from '../../../utils/paginationLimits';
import { IPages } from './interface';

export const Pages: FC<{
  setShowNewPageDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldReloadPages: React.Dispatch<React.SetStateAction<boolean>>;
  shouldReloadPages: boolean;
}> = ({ setShowNewPageDrawer, shouldReloadPages, setShouldReloadPages }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [nameToSearch, setNameToSearch] = useState('');

  const search = useGetSearchString(nameToSearch);

  const [fetchResults, { data, loading }] = useLazyFetch<IPages>(
    `Mobility.AccountBackoffice/api/Ads/GetPages?pageNumber=${pageNumber}&pageSize=${pageSize}${search}`,
  );

  const fetchRef = useRef(fetchResults);

  useEffect(() => {
    if (shouldReloadPages) {
      (async () => {
        try {
          await fetchRef.current();
        } catch (e) {
          logger.log(e);
        }
      })();
    }
  }, [shouldReloadPages, fetchResults, setShouldReloadPages]);

  useEffect(() => {
    (async () => {
      try {
        await fetchRef.current();
      } catch (e) {
        logger.log(e);
      }
    })();
  }, []);

  const handleSearch = () => {
    fetchResults();
  };

  useEffect(() => {
    if (data?.result.results.length) {
      const result = data?.result?.results?.map((r, i) =>
        Object.values({
          PageName: r.name,
          PageID: r.pageId,
          Ads: r.noOfAds,
          dateCreated: DateTime.fromMillis(Date.now(), {
            locale: 'fr',
          }).toLocaleString(),
        }),
      );

      setAds(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.result.results]);

  const [ads, setAds] = useState<
    (string | number | React.FC | JSX.Element)[][]
  >();

  return (
    <>
      <Row useAppMargin justifyContent="space-between">
        <Column fullHeight useAppMargin xs={12} md={6}>
          <Row useAppMargin>
            {/* <Column useAppMargin xs={4} md={2}>
              <Button variant="default" fullWidth>
                Filter
              </Button>
            </Column> */}
            <Column useAppMargin md={10}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSearch();
                }}
              >
                <TextField
                  placeholder="Search Pages"
                  value={nameToSearch}
                  onChange={(e) => setNameToSearch(e.target.value)}
                  rightIcon={<Button>Search</Button>}
                />
              </form>
            </Column>
          </Row>
        </Column>
        <Column useAppMargin xs={12} md={4} lg={2} justifyContent="flex-end">
          <Button fullWidth onClick={() => setShowNewPageDrawer(true)}>
            New Page
          </Button>
        </Column>
      </Row>
      <SizedBox height={24} />

      <Column>
        <Card style={{ padding: '1.5rem' }} fullWidth>
          <SimpleTable
            // scrollable
            loading={loading}
            columns={['Page Name', 'Page ID', 'Ads', 'Date created']}
            data={ads}
          />

          {!loading && !data?.result?.results?.length ? (
            <Text alignment="center" color={Colors.blackGrey}>
              No pages have been added yet
            </Text>
          ) : null}

          <Column>
            {data?.result.results && (
              <Row useAppMargin justifyContent="space-between">
                <Column xs={4} md={2}>
                  <TextField
                    leftIcon="Show:"
                    placeholder={`${pageSize}`}
                    dropDown
                    dropDownOptions={paginationLimits}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                  />
                </Column>
                <Column
                  xs={12}
                  md={8}
                  fullHeight
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Pagination
                    breakLabel="..."
                    pageCount={data.result.totalNumberOfPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(e) => setPageNumber(e.selected + 1)}
                    containerClassName="pagination"
                    activeClassName="active"
                  />
                </Column>
              </Row>
            )}
          </Column>
        </Card>
      </Column>
    </>
  );
};
