import React, { HtmlHTMLAttributes } from 'react';
import { Styles } from './style';

interface IAvatar extends HtmlHTMLAttributes<HTMLDivElement> {
  image?: string | null;
  name?: string;
}
export const Avatar: React.FC<IAvatar> = ({ image, name, ...props }) => {
  const placeholder =
    'https://www.pngitem.com/pimgs/m/22-224249_blank-person-hd-png-download.png';
  return (
    <Styles.Avatar {...props}>
      <img
        src={image || placeholder}
        alt={name}
        onError={(e) => {
          e.currentTarget.src = placeholder;
        }}
      />
    </Styles.Avatar>
  );
};
