export const useGetSearchMobile = (searchMobile: string) => {
  if (searchMobile.length === 11) {
    return `&search=${searchMobile}`;
  }

  return '';
};

export const useGetSearchString = (searchString: string) => {
  if (searchString.length !== 0) {
    return `&search=${searchString}`;
  }

  return '';
};

export const useGetSearchMobilePattern = (searchMobile: string) => {
  if (searchMobile.length === 11) {
    return `/searchPattern?searchPattern=${searchMobile}`;
  }

  return '';
};
