import React, { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { PageBody } from '../../components/UiKit/PageBody';
import { TopBar } from '../../components/TopBar';
import { Column } from '../../components/UiKit/Column';
import { Row } from '../../components/UiKit/Row';
import {
  ISinglePackage,
  IError,
  emptyError,
  IPrepaidPackage,
} from './interface';
import { Card } from '../../components/UiKit/Card';
import { TextField } from '../../components/UiKit/TextField';
import { getFieldError } from '../../utils/formikHelper';
import { Colors } from '../../themes/colors';
import { ReactComponent as DownloadIcon } from '../../assets/images/download-icon.svg';
import { Text } from '../../components/UiKit/Text';
import { Styles } from './styles';
import { rem } from '../../utils/rem';
import { Button } from '../../components/UiKit/Button';
import { logger } from '../../utils/logger';
import { usePost } from '../../hooks/useRequests';
import { SizedBox } from '../../components/UiKit/SizedBox';
import { Modal } from '../../components/UiKit/Modal';
import { useGlobalStore } from '../../store';
import { ErrorBox } from '../../components/UiKit/ErrorBox';

interface IProps {
  selectedPackage: ISinglePackage;
  setShowEditPage: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedPackage: React.Dispatch<
    React.SetStateAction<ISinglePackage | undefined>
  >;
  fetchResults: (
    fetchParams?: any,
  ) => Promise<{
    loading: boolean;
    data: IPrepaidPackage;
    error: null;
  }>;
}
export const EditPrepaidPlan: FC<IProps> = ({
  selectedPackage,
  setSelectedPackage,
  setShowEditPage,
  fetchResults,
}) => {
  const history = useHistory();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [updateError, setUpdateError] = useState<IError>(emptyError);
  const [updatePackageSucess, setUpdatePackageSuccess] = useState(false);
  const [fileError, setFileError] = useState<string>('');
  const [, setSelectedFile] = useState<File>(new File([''], ''));
  const [imagePreview, setImagePreview] = useState('');
  const placeholder =
    'https://via.placeholder.com/350x65?text=No Package Image';

  const {
    state: {
      auth: { user },
    },
  } = useGlobalStore();

  const [editPackage, { error: editError, loading: editing }] = usePost<
    Response
  >(`Mobility.AccountBackOffice/api/PrepaidPlans/EditPlan`);

  const formik = useFormik({
    initialValues: {
      description: selectedPackage.description,
      offeringName: selectedPackage.name,
    },
    validationSchema: Yup.object({
      offeringName: Yup.string()
        .required('Package name is required')
        .min(2)
        .max(30),
      description: Yup.string()
        .required('Package description is required')
        .min(2)
        .max(400),
    }),
    onSubmit: () => {
      setShowConfirmationModal(true);
    },
  });

  const handlePlanUpdate = async () => {
    try {
      const response = await editPackage({
        ...formik.values,
        id: Number(selectedPackage?.id),
      });
      if (response.data) {
        setShowConfirmationModal(false);
        setUpdatePackageSuccess(true);
      }
    } catch (errorResp) {
      logger.log(errorResp);
    }
  };

  const isFileImage = (file: File) => {
    const acceptedImageTypes = ['image/jpeg', 'image/png'];

    return file && acceptedImageTypes.includes(file.type);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files instanceof FileList) {
      if (isFileImage(event.target.files[0])) {
        const file = event.target.files[0];
        const fileSize = parseFloat(`${file.size / (1024 * 1024)}`).toFixed(2);

        if (Number(fileSize) > 1) {
          setFileError('Please select image size less than 1 MB');
        } else {
          const img = new Image();

          img.src = window.URL.createObjectURL(file);

          img.onload = () => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;

            if (width <= 1200 && height <= 628) {
              // ok
              setFileError('');
              setImagePreview(URL.createObjectURL(file));

              setSelectedFile(file);
            } else {
              // error
              setFileError('Image must have width <= 1200 and height <= 628');
            }
          };
        }
      } else {
        setFileError('Please select a valid JPEG or PNG file');
      }
    } else {
      setFileError('invalid file selected');
    }
  };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const handleClick = () => {
    if (hiddenFileInput.current !== null) {
      hiddenFileInput && hiddenFileInput.current.click();
    }
  };

  useEffect(() => {
    if (editError) {
      setUpdateError(editError);
    }
  }, [editError]);

  const UpdateError = updateError?.message && (
    <ErrorBox>{updateError?.message}</ErrorBox>
  );

  const renderModals = () => (
    <>
      <Modal
        isVisible={showConfirmationModal}
        onClose={() => {
          setShowConfirmationModal(false);
          setUpdateError(emptyError);
        }}
        header={{ title: 'Confirmation' }}
        size="sm"
      >
        {UpdateError}

        <SizedBox height={15} />
        <Column>
          <Text>Hi {user?.name},</Text>
          <SizedBox height={15} />
          <Text>Please confirm package update</Text>
          <SizedBox height={10} />
          <Row useAppMargin>
            <Column xs={6} useAppMargin>
              <Button
                onClick={() => handlePlanUpdate()}
                isLoading={editing}
                fullWidth
              >
                Confirm
              </Button>
            </Column>
            <Column xs={6} useAppMargin>
              <Button
                onClick={() => {
                  setShowConfirmationModal(false);
                  setUpdateError(emptyError);
                }}
                outline
                fullWidth
                variant="tertiary"
              >
                Cancel
              </Button>
            </Column>
          </Row>
        </Column>
      </Modal>

      <Modal
        isVisible={updatePackageSucess}
        onClose={() => {
          setUpdatePackageSuccess(false);
          history.push('/prepaid-package');
        }}
        size="sm"
      >
        <SizedBox height={15} />
        <Column>
          <Text>Hi {user?.name},</Text>
          <SizedBox height={15} />
          <Text>Advert updated successfully</Text>
          <SizedBox height={10} />
          <Button
            onClick={() => {
              fetchResults();
              setUpdatePackageSuccess(false);
              setShowEditPage(false);
              setSelectedPackage(undefined);
            }}
            fullWidth
          >
            Done
          </Button>
        </Column>
      </Modal>
    </>
  );

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <TopBar name="Edit Prepaid Plan" />
        <PageBody>
          <Row justifyContent="flex-end">
            <Button type="submit" isLoading={editing}>
              Update
            </Button>
          </Row>
          <SizedBox height={20} />
          <Row childGap={10}>
            <Column xs={12} lg={8} fullHeight style={{ flex: 1 }}>
              <Card fullHeight fullWidth>
                <TextField
                  label="Package Name"
                  placeholder="Enter Package name"
                  {...formik.getFieldProps('offeringName')}
                  type="text"
                  error={getFieldError(
                    formik.errors.offeringName,
                    formik.touched.offeringName,
                  )}
                  minLength={2}
                  maxLength={30}
                />

                <TextField
                  label="Package Description"
                  placeholder="Enter Package description"
                  {...formik.getFieldProps('description')}
                  type="text"
                  multiline
                  as="textarea"
                  error={getFieldError(
                    formik.errors.description,
                    formik.touched.description,
                  )}
                  minLength={2}
                  maxLength={400}
                />
              </Card>
            </Column>
            <Column xs={12} lg={4} fullHeight>
              <Card fullHeight fullWidth>
                <Column
                  alignItems="center"
                  justifyContent="center"
                  onClick={handleClick}
                  style={{
                    border: `dotted 2px ${Colors.grey}`,
                    padding: imagePreview !== '' ? '0px' : rem(30),
                    borderRadius: '5px',
                  }}
                  fullHeight
                >
                  {imagePreview !== '' ? (
                    <Column
                      onClick={handleClick}
                      style={{ position: 'relative' }}
                    >
                      <Styles.CloseButton
                        onClick={() => {
                          setImagePreview('');
                          setSelectedFile(new File([''], ''));
                        }}
                      >
                        x
                      </Styles.CloseButton>
                      <img
                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                        src={imagePreview}
                        onError={(e) => {
                          e.currentTarget.src = placeholder;
                        }}
                        alt="ad"
                      />
                    </Column>
                  ) : (
                    <>
                      <DownloadIcon />
                      <Text>Click to browse</Text>
                      <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{ display: 'none' }}
                      />
                    </>
                  )}
                </Column>
                <SizedBox height={15} />

                <Button fullWidth variant="tertiary" outline>
                  Upload
                </Button>
                {fileError && (
                  <Text color={Colors.orange}>{fileError.toString()}</Text>
                )}
              </Card>
            </Column>
          </Row>
        </PageBody>
      </form>
      {renderModals()}
    </>
  );
};
