// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
// import { FaWallet } from 'react-icons/fa';

import { DateTime } from 'luxon';
import { Card } from '../../components/UiKit/Card';
import { PageBody } from '../../components/UiKit/PageBody';
import { Text } from '../../components/UiKit/Text';
import { Column } from '../../components/UiKit/Column';
import { SizedBox } from '../../components/UiKit/SizedBox';
import { Row } from '../../components/UiKit/Row';
import { TopBar } from '../../components/TopBar';
import { Button } from '../../components/UiKit/Button';
import { TextField } from '../../components/UiKit/TextField';
import { SimpleTable } from '../../components/UiKit/Table';
// import { Avatar } from '../../components/UiKit/Avatar';
import { generateShortId } from '../../utils/generateShortId';
import { useFetch, useLazyFetch } from '../../hooks/useRequests';
import { IAudit, ISingleAudit } from './interface';
import { Drawer } from '../../components/RightDrawer';
import { ErrorBox } from '../../components/UiKit/ErrorBox';
import { paginationLimits } from '../../utils/paginationLimits';
import { Pagination } from '../../components/UiKit/Pagination';
import { exportToExcel } from '../../utils/exportToExcel';
import { Spinner } from '../../components/UiKit/Spinner';

export const Audit = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const [showDrawer, setShowDrawer] = useState(false);
  const [onRowClick, setOnRowClick] = useState<(() => void)[] | (() => void)>();

  const [drawerData, setDrawerData] = useState<
    IAudit['result']['results'][0]
  >();

  const url = `Mobility.OnboardingBackOffice/api/Audits/GetAudits?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  const singleAuditUrl = `Mobility.OnboardingBackOffice/api/Audits/GetAudit?id=${drawerData?.id}`;

  const { data, loading, error } = useFetch<IAudit>(url);
  const [
    fetchSingleAudit,
    { data: singleAuditData, loading: loadingAudit, error: singleAuditError },
  ] = useLazyFetch<ISingleAudit>(singleAuditUrl);

  const [audit, setAudit] = useState<
    (string | number | React.FC | JSX.Element)[][]
  >();

  useEffect(() => {
    if (data?.result?.results?.length) {
      const result = data?.result?.results?.map((r, i) =>
        Object.values({
          'S/N': i + 1,
          date: (
            <Text>
              {DateTime.fromISO(r?.auditDateTimeUtc, {
                locale: 'ng',
              }).toLocaleString(DateTime.DATE_MED)}
            </Text>
          ),
          user: r.auditUser,
          action: <Text key={generateShortId()}>{r.actionTaken}</Text>,
          time: (
            <Text>
              {DateTime.fromISO(r?.auditDateTimeUtc, {
                locale: 'ng',
              }).toLocaleString(DateTime.TIME_SIMPLE)}
            </Text>
          ),
          IP: r?.ip,
        }),
      );

      setAudit(result);

      const methods = data?.result?.results?.map((r, i) => () => {
        setShowDrawer(true);
        setDrawerData(r);
      });

      setOnRowClick(methods);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.result?.results]);

  useEffect(() => {
    if (drawerData) {
      fetchSingleAudit();
    }
  }, [drawerData, fetchSingleAudit]);

  return (
    <>
      <TopBar name="Audit" />
      <Drawer showDrawer={showDrawer} setShowDrawer={setShowDrawer}>
        <>
          {singleAuditError && <ErrorBox>{singleAuditError?.message}</ErrorBox>}
          {loadingAudit ? (
            <Spinner isFixed>Loading audit details</Spinner>
          ) : (
            <>
              <Text>Audit</Text>
              <SizedBox height={70} />
              <Row useAppMargin>
                <Column useAppMargin xs={6} style={{ marginBottom: '40px' }}>
                  <Text color="#8181A5" size={14} variant="lighter">
                    User
                  </Text>
                  <Text>
                    {singleAuditData?.result?.firstName} &nbsp;
                    {singleAuditData?.result?.lastName}
                  </Text>
                </Column>
                <Column useAppMargin xs={6} style={{ marginBottom: '40px' }}>
                  <Text color="#8181A5" size={14} variant="lighter">
                    Email
                  </Text>
                  <Text>{singleAuditData?.result?.email}</Text>
                </Column>
                <Column useAppMargin xs={6} style={{ marginBottom: '40px' }}>
                  <Text color="#8181A5" size={14} variant="lighter">
                    Action
                  </Text>
                  <Text>{singleAuditData?.result?.actionTaken}</Text>
                </Column>
                <Column useAppMargin xs={6} style={{ marginBottom: '40px' }}>
                  <Text color="#8181A5" size={14} variant="lighter">
                    Action Status
                  </Text>
                  <Text>{singleAuditData?.result?.status}</Text>
                </Column>
                <Column useAppMargin xs={6} style={{ marginBottom: '40px' }}>
                  <Text color="#8181A5" size={14} variant="lighter">
                    Action Date
                  </Text>
                  <Text>
                    {DateTime.fromISO(
                      singleAuditData?.result?.auditDateTimeUtc || '',
                      {
                        locale: 'ng',
                      },
                    ).toLocaleString(DateTime.DATETIME_MED)}
                  </Text>
                </Column>

                <Column useAppMargin xs={6} style={{ marginBottom: '40px' }}>
                  <Text color="#8181A5" size={14} variant="lighter">
                    User role
                  </Text>
                  <Text>{singleAuditData?.result?.userRoleName}</Text>
                </Column>
              </Row>
            </>
          )}
        </>
      </Drawer>
      <PageBody>
        <Row useAppMargin justifyContent="space-between">
          <Column fullHeight useAppMargin xs={12} md={6}>
            <Row useAppMargin>
              {/* <Column useAppMargin xs={4} md={2}>
                <Button variant="default" fullWidth>
                  Filter
                </Button>
              </Column> */}
              <Column useAppMargin xs={6} md={8}>
                <TextField placeholder="Search Audit" />
              </Column>
            </Row>
          </Column>
          <Column xs={12} md={4} lg={2} justifyContent="flex-end">
            <Button
              disabled={!data?.result?.results?.length}
              onClick={() =>
                exportToExcel(`${url}&exportToExcel=true`, 'Audit')
              }
              fullWidth
            >
              Export CSV
            </Button>
          </Column>
        </Row>
        <SizedBox height={24} />
        <Column>
          <Card style={{ padding: '1.5rem' }} fullWidth>
            <SimpleTable
              loading={loading}
              columns={['S/N', 'Date', 'User', 'Action', 'Time', 'IP Address']}
              data={audit}
              onRowClick={onRowClick}
            />
            {error && <ErrorBox>{error.message}</ErrorBox>}

            <Column>
              {data?.result?.results && (
                <Row useAppMargin justifyContent="space-between">
                  <Column xs={4} md={2}>
                    <TextField
                      leftIcon="Show:"
                      placeholder={`${pageSize}`}
                      dropDown
                      dropDownOptions={paginationLimits}
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    />
                  </Column>
                  <Column
                    xs={12}
                    md={8}
                    fullHeight
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Pagination
                      breakLabel="..."
                      pageCount={data?.result?.totalNumberOfPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={(e) => setPageNumber(e.selected + 1)}
                      containerClassName="pagination"
                      activeClassName="active"
                    />
                  </Column>
                </Row>
              )}
            </Column>
          </Card>
        </Column>
      </PageBody>
    </>
  );
};
