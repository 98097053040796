import React, { memo, useEffect, useState } from 'react';
import { Column } from '../../components/UiKit/Column';
import { TextField } from '../../components/UiKit/TextField';
import { generateShortId } from '../../utils/generateShortId';
import { SizedBox } from '../../components/UiKit/SizedBox';
import { Text } from '../../components/UiKit/Text';
import { Card } from '../../components/UiKit/Card';
import { ICards, IError, emptyError } from './interface';
import { Row } from '../../components/UiKit/Row';
import { useFetch, usePost } from '../../hooks/useRequests';
import { Spinner } from '../../components/UiKit/Spinner';
import { logger } from '../../utils/logger';
import { Button } from '../../components/UiKit/Button';
import { Modal } from '../../components/UiKit/Modal';
import { useGlobalStore } from '../../store';
import { ErrorBox } from '../../components/UiKit/ErrorBox';

export const CardOrdering = memo(() => {
  const [cards, setCards] = useState<ICards[]>([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [updateError, setUpdateError] = useState<IError>(emptyError);

  const {
    state: {
      auth: { user },
    },
  } = useGlobalStore();
  const { data: cardsData, loading: cardsLoading, refetch } = useFetch<
    ICards[]
  >(`Mobility.EShop/api/SimReservation/GetCardIndex`);

  const [updateCardsIndex, { error: orderErr, loading: updating }] = usePost<
    Response
  >(`Mobility.EShop/api/SimReservation/AddCardIndex`);

  useEffect(() => {
    if (cardsData) {
      setCards(cardsData);
    }
  }, [cardsData]);

  const handleCardOrdering = async () => {
    setUpdateError({
      message: '',
      responseCode: 0,
    });
    if (validEntries()) {
      try {
        const response = await updateCardsIndex(cards);

        if (response.data) {
          setShowSuccessModal(true);
        }
      } catch (errorEres) {
        logger.log(errorEres);
      }
    } else {
      setUpdateError({
        message: `Entries must be between 0 - ${
          cards.length - 1
        }. & duplicates are not allowed `,
        responseCode: 0,
      });
    }
  };

  const validEntries = () => {
    const c = [...cards];
    const indexes = c.map((cad) => Number(cad.index));
    const indexValues = c.filter(
      (cad) => Number(cad.index) < cards.length && Number(cad.index) >= 0,
    );

    const noDuplicates = new Set(indexes).size === cards.length;
    const validValues = indexValues.length === cards.length;

    return noDuplicates && validValues;
  };

  useEffect(() => {
    if (orderErr) {
      setUpdateError(orderErr);
    }
  }, [orderErr]);

  const UpdateError = updateError?.message && (
    <ErrorBox>{updateError?.message}</ErrorBox>
  );

  const renderModals = () => (
    <>
      <Modal
        isVisible={showSuccessModal}
        onClose={() => {
          setShowSuccessModal(false);
          refetch();
        }}
        size="sm"
      >
        <SizedBox height={15} />
        <Column>
          <Text>Hi {user?.name},</Text>
          <SizedBox height={15} />
          <Text>Card order saved successfully</Text>
          <SizedBox height={10} />
          <Button
            onClick={() => {
              setShowSuccessModal(false);
              refetch();
            }}
            fullWidth
          >
            Done
          </Button>
        </Column>
      </Modal>
    </>
  );

  return (
    <>
      <Card fullHeight fullWidth style={{ padding: '20px' }}>
        <Text weight={600} size={16}>
          App Dashboard Cards
        </Text>
        <SizedBox height={20} />

        {cardsLoading ? (
          <Spinner isFixed />
        ) : (
          <>
            {cardsData && cardsData.length > 0 ? (
              cardsData.map((card, index) => {
                return (
                  <Row
                    key={generateShortId()}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Column xs={6}>{card.cardId}</Column>
                    <Column xs={3}>
                      <TextField
                        max={6}
                        min={0}
                        type="number"
                        defaultValue={card.index}
                        onBlur={(e) => {
                          const cad = [...cards];
                          cad[index].index = e.target.value;
                          setCards(cad);
                        }}
                      />
                    </Column>
                  </Row>
                );
              })
            ) : (
              <Text>No card data available</Text>
            )}
            <SizedBox height={20} />

            {UpdateError}
            <SizedBox height={20} />

            <Column justifyContent="center">
              <Button onClick={handleCardOrdering} isLoading={updating}>
                Update
              </Button>
            </Column>
          </>
        )}
      </Card>
      {renderModals()}
    </>
  );
});
