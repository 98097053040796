import React, { FC, useEffect, useRef, useState } from 'react';

import { LineChart, Line, XAxis, Tooltip } from 'recharts';

import { Button } from '../../components/UiKit/Button';
import { Row } from '../../components/UiKit/Row';
import { Spinner } from '../../components/UiKit/Spinner';
import { Text } from '../../components/UiKit/Text';
import { useFetch } from '../../hooks/useRequests';
import { IChart } from './interface';

interface KeyTotal {
  key: string;
  total: number;
}

interface CProps {
  chartTitle: string;
  endpointUrl: string;
}

export const UserChart: FC<CProps> = ({ chartTitle, endpointUrl }) => {
  const { data: chartData, loading: chartLoading } = useFetch<IChart>(
    endpointUrl,
  );

  const [daysResult, setDaysResult] = useState<KeyTotal[]>([]);

  const [weeksResult, setWeeksResult] = useState<KeyTotal[]>([]);

  const [monthsResult, setMonthsResult] = useState<KeyTotal[]>([]);

  // This approach is not optimal; need to update this
  useEffect(() => {
    if (chartData?.result.days) {
      const days = chartData?.result.days.map((day, i) => {
        const keys = Object.keys(day)[0];

        return {
          key: keys,
          total: day[keys],
        };
      });

      setDaysResult(days);
    }

    if (chartData?.result.weeks) {
      const weeks = chartData?.result.weeks.map((week, i) => {
        const keys = Object.keys(week)[0];

        return {
          key: `week ${keys}`,
          total: week[keys],
        };
      });
      setWeeksResult(weeks);
    }

    if (chartData?.result.weeks) {
      const months = chartData?.result.months.map((month, i) => {
        const keys = Object.keys(month)[0];

        return {
          key: keys,
          total: month[keys],
        };
      });

      setMonthsResult(months);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData?.result]);

  const [selcetedChart, setSelcetedChart] = useState<KeyTotal[]>();

  useEffect(() => {
    setSelcetedChart(daysResult);
  }, [daysResult]);

  const parentRef = useRef<HTMLDivElement>(null);

  const getDimensions = () => {
    if (parentRef.current) {
      return {
        width: parentRef.current.clientWidth,
        height: 300,
      };
    }

    return null;
  };

  const [activeButton, setActiveButton] = useState('Day');

  const handleSelect = (data: KeyTotal[], name: string) => {
    setSelcetedChart(data);
    setActiveButton(name);
  };

  return (
    <div ref={parentRef} style={{ width: '100%' }}>
      <Row justifyContent="space-between" alignItems="center">
        <Text style={{ marginBottom: '1rem' }}>{chartTitle}</Text>
        <Row>
          <Button
            style={{ marginRight: '5px' }}
            variant="default"
            outline={activeButton === 'Day'}
            onClick={() => handleSelect(daysResult, 'Day')}
            size="small"
          >
            Day
          </Button>
          <Button
            style={{ marginRight: '5px' }}
            variant="default"
            outline={activeButton === 'Week'}
            onClick={() => handleSelect(weeksResult, 'Week')}
            size="small"
          >
            Week
          </Button>
          <Button
            variant="default"
            outline={activeButton === 'Month'}
            onClick={() => handleSelect(monthsResult, 'Month')}
            size="small"
          >
            Month
          </Button>
        </Row>
      </Row>
      {chartLoading && <Spinner isFixed />}
      {selcetedChart && (
        <LineChart
          width={getDimensions()?.width}
          height={getDimensions()?.height}
          style={{ width: '100%' }}
          data={selcetedChart}
          margin={{ top: 20, right: 20, bottom: 20, left: 10 }}
        >
          <XAxis dataKey="key" />
          <Tooltip />
          <Line
            type="monotone"
            strokeWidth={2}
            yAxisId={0}
            dataKey="total"
            stroke="#006848"
            key={0}
          />
          {/* <Brush dataKey="value" startIndex={selcetedChart.length - 40}>
            <AreaChart>
              <CartesianGrid />
              <YAxis hide dataKey="value" />
              <Area dataKey="value" stroke="#ff7300" fill="#ff7300" />
            </AreaChart>
          </Brush> */}
        </LineChart>
      )}
    </div>
  );
};
